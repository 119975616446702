import { Route, useLocation } from 'react-router-dom';
import { ExternalRedirect } from '../common/external-redirect';
import { NavigateWithParams } from '../common/navigate-with-params';
import { Utility } from 'utility/utility';
import { Routing } from '../common/routing';
import React, { Suspense } from 'react';
import routeTranslations from 'assets/json/route-translations';
import { FallbackWithParams } from '../common/fallback-with-params';
import { useLocale } from 'hooks/use-locale';
import { LegacyRouter } from './legacy-router';

export const DefaultRouter = () => {
  const locale = useLocale();
  const location = useLocation();
  const pathLocale = location.pathname.split('/').filter((x) => x && x !== '')[0];

  const getTranslationObject = (route, locale) => {
    if (!Utility.isLocaleValid(locale)) {
      return {};
    }
    let current = routeTranslations[locale.toLowerCase()];
    const parts = route.split('/').filter((x) => x && x !== '' && x !== ':locale');
    for (let i = 0; i < parts.length; i++) {
      const key = parts[i];
      current = current[key];
      if (!current) break;
    }

    return current;
  };

  const translateCurrentPath = (currentRoute, locale) => {
    currentRoute ??= '';
    if (!Utility.isLocaleValid(locale)) {
      return currentRoute;
    }

    let current = routeTranslations[locale.toLowerCase()];
    const parts = currentRoute.split('/').filter((x) => x && x !== '' && x !== ':locale');
    for (let i = 0; i < parts.length; i++) {
      const key = parts[i];
      parts[i] = current[key]?.__name ?? key;
      current = current[key];
      if (!current) break;
    }

    return parts.join('/');
  };

  const wrapWithSuspense = (Component) => {
    return (props) => (
      <Suspense>
        <Component {...props} />
      </Suspense>
    );
  };

  const getStaticProps = (obj) => {
    const propNames = Object.keys(obj)
      .filter((x) => x.startsWith('__$'))
      .map((x) => x.substring(3));
    const props = {};
    for (const prop of propNames) {
      const propKey = prop.toLowerCase();
      const propValue = obj[`__$${prop}`];
      props[propKey] = propValue;
    }
    return props;
  };

  const fillInRoute = (route, locale) => {
    route = route.replace(/:count|:locale/g, locale).toLowerCase();
    return route;
  };

  const ObjectRoute = (locale, nestedRoutes, currentPath = '', activated = true) => {
    let component = nestedRoutes.__component;
    const splitedCurrentPath = currentPath.split('/').filter((x) => x && x.trim() !== '');
    currentPath = splitedCurrentPath.join('/');

    let translatedPath = translateCurrentPath(splitedCurrentPath.join('/'), locale);

    const subRoutes = Object.keys(nestedRoutes)
      .filter((x) => !x.startsWith('__'))
      .flatMap((x) =>
        ObjectRoute(
          locale,
          nestedRoutes[x],
          splitedCurrentPath.join('/') + '/' + x,
          nestedRoutes[x].__activated !== false && activated !== false,
        ),
      );

    const translatedObject = getTranslationObject(currentPath, locale) ?? {};

    let fallback = translatedObject.__fallback ?? nestedRoutes.__fallback;
    const redirect = translatedObject.__redirect ?? nestedRoutes.__redirect;
    if (translatedObject.__activated !== undefined)
      activated = activated && (translatedObject.__activated ?? nestedRoutes.__activated ?? true);

    if (redirect) {
      if (redirect.startsWith('/')) {
        component = <NavigateWithParams to={fillInRoute(redirect, locale)} />;
      } else {
        component = <ExternalRedirect path={redirect}></ExternalRedirect>;
      }
    } else {
      if (component?._init) {
        component = wrapWithSuspense(component);
      }

      if (component) {
        component = React.createElement(component, getStaticProps(nestedRoutes));
      }
    }

    if (currentPath === '') {
      fallback ??= '/:count/404';
    }

    return [
      ...subRoutes,
      component ? (
        <Route
          key={translatedPath}
          path={translatedPath ? '/:count/' + translatedPath : '/:count/'}
          element={component}
        ></Route>
      ) : null,
      fallback ? (
        <Route
          key={currentPath + '*'}
          path={'/:count/' + translatedPath + '*'}
          element={<FallbackWithParams to={fillInRoute(fallback, locale)} />}
        />
      ) : null,
    ];
  };
  return Utility.isLocaleValid(pathLocale) ? (
    ObjectRoute(locale, Routing)
  ) : (
    <Route index element={<LegacyRouter></LegacyRouter>}></Route>
  );
};
