import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import Contenttext from './includes/Contenttext';
import { useEffect } from 'react';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';

function Textpage(props) {
  let param = useParams();
  let arrla;
  let arrla1 = '';
  let [passedprops, setpassedprops] = useState('');

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let la;

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let [renderdata, setrenderdata] = useState();
  useEffect(() => {
    if (props.text) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/texts?locale=' + arrla1 + '&filters[Slug][$eq]=' + props.text,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setrenderdata(json);
        });
    }
  }, []);
  useEffect(() => {
    if (props.text) {
      setpassedprops(props.text);
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/texts?locale=' + arrla1 + '&filters[Slug][$eq]=' + props.text,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setrenderdata(json);
        });
    }
  }, [props.text]);
  let slicedtext = props.text.split('-');
  console.log(slicedtext);
  let slicedtextinclude = slicedtext[0];
  if (props.text == 'Text-content-Impressum') {
    slicedtextinclude = 'legal';
  }
  if (props.text == 'Text-content-Agbs') {
    slicedtextinclude = 'terms';
  }
  if (props.text == 'Text-content-Datenschutz') {
    slicedtextinclude = 'privacy-policy';
  }

  return (
    <>
      <Helmet>
        <title>{renderdata ? renderdata.data[0].attributes.MetaTitle : 'Q-railing'}</title>
        <meta
          name="description"
          content={renderdata ? renderdata.data[0].attributes.MetaText : 'Q-railing Textpage'}
        ></meta>
        <link rel="alternate" hreflang="da-DK" href={'https://q-railing.com/da-dk/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="de-AT" href={'https://q-railing.com/de-at/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="de-CH" href={'https://q-railing.com/de-ch/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="de-DE" href={'https://q-railing.com/de-de/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="x-default" href={'https://q-railing.com/en/' + slicedtextinclude + '/'} />
        {slicedtextinclude != 'legal' && (
          <link rel="alternate" hreflang="en-CA" href={'https://q-railing.com/en-ca/' + slicedtextinclude + '/'} />
        )}
        <link rel="alternate" hreflang="en-AT" href={'https://q-railing.com/en-ca/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="en-GB" href={'https://q-railing.com/en-gb/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="en-IE" href={'https://q-railing.com/en-ie/' + slicedtextinclude + '/'} />
        {slicedtextinclude != 'legal' && (
          <link rel="alternate" hreflang="en-US" href={'https://q-railing.com/en-us/' + slicedtextinclude + '/'} />
        )}
        <link rel="alternate" hreflang="es" href={'https://q-railing.com/es/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="es-ES" href={'https://q-railing.com/es-es/' + slicedtextinclude + '/'} />
        {slicedtextinclude != 'legal' && (
          <link rel="alternate" hreflang="es-US" href={'https://q-railing.com/es-us/' + slicedtextinclude + '/'} />
        )}
        <link rel="alternate" hreflang="fi-FI" href={'https://q-railing.com/fi-fi/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="fr-BE" href={'https://q-railing.com/fr-be/' + slicedtextinclude + '/'} />
        {slicedtextinclude != 'legal' && (
          <link rel="alternate" hreflang="fr-CA" href={'https://q-railing.com/fr-ca/' + slicedtextinclude + '/'} />
        )}
        <link rel="alternate" hreflang="fr-CH" href={'https://q-railing.com/fr-ch/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="fr-FR" href={'https://q-railing.com/fr-fr/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="fr-LU" href={'https://q-railing.com/fr-lu/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="it-CH" href={'https://q-railing.com/it-ch/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="it-IT" href={'https://q-railing.com/it-it/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="nb-NO" href={'https://q-railing.com/nb-no/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="nl-BE" href={'https://q-railing.com/nl-be/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="nl-NL" href={'https://q-railing.com/nl-nl/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="pl-PL" href={'https://q-railing.com/pl-pl/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="pt-BR" href={'https://q-railing.com/pt-br/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="pt-PT" href={'https://q-railing.com/pt-pt/' + slicedtextinclude + '/'} />
        <link rel="alternate" hreflang="sv-SE" href={'https://q-railing.com/sv-se/' + slicedtextinclude + '/'} />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <Contenttext text={renderdata} idclass={passedprops}></Contenttext>
      {renderdata && <NeedHelp color={'grey'}></NeedHelp>}
      {renderdata && <Footernew></Footernew>}
    </>
  );
}

export default Textpage;
