import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Systemgrid.css';
import LinkData from './Links.json';

function Systemgrid() {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let [linkarr, setlinkarr] = useState([]);
  let la;
  let arrla;
  let para = useParams();
  let param = useParams();
  let arrla1 = '';
  let [activetoggle, setactivetoggle] = useState('all');
  let [PageTranslations, setPageTranslations] = useState({ data: [] });
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];
      if (para.count) {
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 1000);
    return () => clearTimeout(timeout);
  }, [systemarr]);

  useEffect(() => {
    decidethepaths();

    const cachedData = localStorage.getItem(para.count + '-cachedDataSystemCards');
    if (cachedData) {
      let now = new Date();
      setsystemarr(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(para.count + '-cachedDataSystemCards');
      }
    } else {
      let now = new Date();

      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
          arrla1 +
          '&populate=CardImage&sort[0]=Priority:desc&pagination[pageSize]=50',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
          localStorage.setItem(
            para.count + '-cachedDataSystemCards',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        })
        .then(() => reloadgrid());
    }

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=FilterTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setPageTranslations(json);
      });
  }, []);

  function loadfilter(prop) {
    if (prop) {
      setactivetoggle(prop);
      if (prop != 'all') {
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&filters[' +
            prop +
            '][$eq]=true&populate=CardImage&sort[0]=Priority:desc',
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            setsystemarr(json);
          })
          .then(() => {
            if (systemarr) {
              reloadgrid();
            }
          });
      } else {
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage&sort[0]=Priority:desc&pagination[pageSize]=50',
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            setsystemarr(json);
          })
          .then(() => reloadgrid());
      }
    }
  }
  function reloadgrid() {
    if (systemarr.data.length > 0) {
      return (
        <>
          {systemarr.data.map((item, index) => {
            return (
              <>
                <div key={'KEY' + index} className={`systemgrid-card ${animate ? 'animateCard' : ''}`}>
                  <Link hrefLang={param.count} to={'/' + para.count + linkarr[8] + item.attributes.Slug + '/'}>
                    <img
                      alt="System-Image"
                      src={item.attributes.CardImage.data.attributes.url}
                      width="256"
                      height="256"
                    />
                    {item.attributes.Title}
                  </Link>
                </div>
              </>
            );
          })}
        </>
      );
    }
  }

  return (
    <>
      <div className="systemgrid-wrap">
        <div className="systemgrid-inner">
          <div className="systemgrid-head">
            <div className="systemgrid-head-text"></div>
            <div className="projectblock-filter">
              <button className={activetoggle == 'all' ? 'activegridbtn' : ''} onClick={() => loadfilter('all')}>
                {PageTranslations.data.length > 0 ? PageTranslations.data[0].attributes.FreeData1.data[0] : 'All'}
              </button>
              <button
                className={activetoggle == 'FilterGlassrailBaseChannelsDisk' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterGlassrailBaseChannelsDisk')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[1]
                  : 'Glassrail basechannels disk'}
              </button>
              <button
                className={activetoggle == 'FilterGlassrailBaseChannelsWedge' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterGlassrailBaseChannelsWedge')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[2]
                  : 'Glassrail basechannels wedge'}
              </button>
              <button
                className={activetoggle == 'FilterGlassrailAdapters' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterGlassrailAdapters')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[3]
                  : 'Glassrail adapters'}
              </button>
              <button
                className={activetoggle == 'FilterJulietBalconies' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterJulietBalconies')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[4]
                  : 'French balcony'}
              </button>
              <button
                className={activetoggle == 'FilterHandrails' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterHandrails')}
              >
                {PageTranslations.data.length > 0 ? PageTranslations.data[0].attributes.FreeData1.data[5] : 'Handrail'}
              </button>
              <button
                className={activetoggle == 'FilterStainlessSteelPosts' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterStainlessSteelPosts')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[6]
                  : 'Stainless steel posts'}
              </button>
              <button
                className={activetoggle == 'FilterGlassrailBaseClamps' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterGlassrailBaseClamps')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[7]
                  : 'Base clamps'}
              </button>
              <button
                className={activetoggle == 'FilterAluminiumPosts' ? 'activegridbtn' : ''}
                onClick={() => loadfilter('FilterAluminiumPosts')}
              >
                {PageTranslations.data.length > 0
                  ? PageTranslations.data[0].attributes.FreeData1.data[8]
                  : 'Aluminium posts'}
              </button>
            </div>
          </div>
          <div className="systemgrid-grid">{reloadgrid()}</div>
        </div>
      </div>
    </>
  );
}

export default Systemgrid;
