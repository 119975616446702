import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import './Aboutpage.css';
import AboutBlock from './includes/AboutBlock';
import Footernew from './includes/Footernew';
import HeaderSlim from './includes/HeaderSlim';
import NewsletterNew from './includes/NewsletterNew';
import { Helmet } from 'react-helmet';

function Aboutpage() {
  let arrla;
  let arrla1;
  let la;
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  let [MetaData, setMetaData] = useState({ data: [] });

  useEffect(() => {
    const cachedData = localStorage.getItem(param.count + '-cachedDataMetaPages');
    if (cachedData && JSON.parse(cachedData).json.data.length > 0) {
      let now = new Date();
      setMetaData(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(param.count + '-cachedDataMetaPages');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=MetaDataPages',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setMetaData(json);
          localStorage.setItem(
            param.count + '-cachedDataMetaPages',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{MetaData.data.length > 0 ? MetaData.data[0].attributes.FreeData1.About[0] : 'Q-railing'}</title>
        <meta
          name="description"
          content={
            MetaData.data.length > 0
              ? MetaData.data[0].attributes.FreeData1.About[1]
              : 'the premium brand in railing systems'
          }
        ></meta>
        <link rel="alternate" hreflang="da-DK" href="https://q-railing.com/da-dk/company/about-us/" />
        <link rel="alternate" hreflang="de-AT" href="https://q-railing.com/de-at/company/about-us/" />
        <link rel="alternate" hreflang="de-CH" href="https://q-railing.com/de-ch/company/about-us/" />
        <link rel="alternate" hreflang="de-DE" href="https://q-railing.com/de-de/company/about-us/" />
        <link rel="alternate" hreflang="x-default" href="https://q-railing.com/en/company/about-us/" />
        <link rel="alternate" hreflang="en-CA" href="https://q-railing.com/en-ca/company/about-us/" />
        <link rel="alternate" hreflang="en-GB" href="https://q-railing.com/en-gb/company/about-us/" />
        <link rel="alternate" hreflang="en-AT" href="https://q-railing.com/en-at/company/about-us/" />
        <link rel="alternate" hreflang="en-IE" href="https://q-railing.com/en-ie/company/about-us/" />
        <link rel="alternate" hreflang="en-US" href="https://q-railing.com/en-us/company/about-us/" />
        <link rel="alternate" hreflang="es" href="https://q-railing.com/es/company/about-us/" />
        <link rel="alternate" hreflang="es-ES" href="https://q-railing.com/es-es/company/about-us/" />
        <link rel="alternate" hreflang="es-US" href="https://q-railing.com/es-us/company/about-us/" />
        <link rel="alternate" hreflang="fi-FI" href="https://q-railing.com/fi-fi/company/about-us/" />
        <link rel="alternate" hreflang="fr-BE" href="https://q-railing.com/fr-be/company/about-us/" />
        <link rel="alternate" hreflang="fr-CA" href="https://q-railing.com/fr-ca/company/about-us/" />
        <link rel="alternate" hreflang="fr-CH" href="https://q-railing.com/fr-ch/company/about-us/" />
        <link rel="alternate" hreflang="fr-FR" href="https://q-railing.com/fr-fr/company/about-us/" />
        <link rel="alternate" hreflang="fr-LU" href="https://q-railing.com/fr-lu/company/about-us/" />
        <link rel="alternate" hreflang="it-CH" href="https://q-railing.com/it-ch/company/about-us/" />
        <link rel="alternate" hreflang="it-IT" href="https://q-railing.com/it-it/company/about-us/" />
        <link rel="alternate" hreflang="nb-NO" href="https://q-railing.com/nb-no/company/about-us/" />
        <link rel="alternate" hreflang="nl-BE" href="https://q-railing.com/nl-be/company/about-us/" />
        <link rel="alternate" hreflang="nl-NL" href="https://q-railing.com/nl-nl/company/about-us/" />
        <link rel="alternate" hreflang="pl-PL" href="https://q-railing.com/pl-pl/company/about-us/" />
        <link rel="alternate" hreflang="pt-BR" href="https://q-railing.com/pt-br/company/about-us/" />
        <link rel="alternate" hreflang="pt-PT" href="https://q-railing.com/pt-pt/company/about-us/" />
        <link rel="alternate" hreflang="sv-SE" href="https://q-railing.com/sv-se/company/about-us/" />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <AboutBlock></AboutBlock>
      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default Aboutpage;
