import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './Mainpage.css';
import './Systems.css';
import './CategoriePage.css';
import Footernew from './includes/Footernew';
import NewsletterNew from './includes/NewsletterNew';
import HeaderSlim from './includes/HeaderSlim';

import { Helmet } from 'react-helmet';

function CategoriePage(props) {
  let param = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  const location = useLocation();
  console.log(systemres);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let la;
  let arrla;
  let arrla1 = '';

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  useEffect(() => {
    if (props.shopcategory) {
      try {
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/landingpages?locale=' +
            arrla1 +
            '&filters[Slug][$eq]=' +
            props.shopcategory +
            '&populate=BannerImage',
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            console.log(json);
            setsystemres(json);
            if (!json.data.length > 0) {
              window.location.replace('/' + param.count + '/404/');
            }
          });
      } catch {
        window.location.replace('/' + param.count + '/404/');
      }
    }
  }, []);

  return (
    <>
      {systemres.data.length > 0 && (
        <Helmet>
          <title>{systemres.data[0].attributes.MetaTitle}</title>
          <meta name="description" content={systemres.data[0].attributes.MetaText}></meta>
        </Helmet>
      )}

      {systemres.data.length > 0 && <HeaderSlim></HeaderSlim>}
      {systemres.data.length > 0 && (
        <div className="Service-wrap">
          <div className="Service-inner" id="CategoriePage-Header">
            <h2>{systemres.data.length > 0 ? systemres.data[0].attributes.BannerTitle : 'Categories'}</h2>
            <p>{systemres.data.length > 0 ? systemres.data[0].attributes.BannerText : ''}</p>
          </div>
        </div>
      )}

      {systemres.data.length > 0 && (
        <div className="CategoryPage-body-wrap">
          <div className="CategoryPage-body-inner">
            {systemres.data[0].attributes.FreeData1 &&
              systemres.data[0].attributes.FreeData1.Categories.map((item) => {
                return (
                  <a className="CategoriePage-Card" href={item[2]} hrefLang={param.count}>
                    <div>
                      <img src={item[1]} />
                      <p>{item[0]}</p>
                    </div>
                  </a>
                );
              })}
          </div>
        </div>
      )}

      {systemres.data.length > 0 && <NewsletterNew color="grey"></NewsletterNew>}
      {systemres.data.length > 0 && <Footernew></Footernew>}
    </>
  );
}

export default CategoriePage;
