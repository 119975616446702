import React from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import './Searchresults.css';
import { useEffect } from 'react';
import Links from './includes/Links.json';

function SearchresultsMeili(props) {
  let para = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  let searchWord;
  let [filterword, setfilterword] = useState('all');
  let [btnsproduct, setbtnsproduct] = useState([]);
  let [btnsdocs, setbtnsdocs] = useState([]);
  let [pagelimitbot] = useState(0);
  let [pagelimittop] = useState(12);

  let [finalsystems, setfinalsystems] = useState([]);

  let [currentPage, setCurrentPage] = useState(0);
  let [currentDocsPage, setCurrentDocsPage] = useState(0);

  let [producthits, setproducthits] = useState(0);
  let [docshits, setdocshits] = useState(0);

  let [pagelimitbotdocs] = useState(0);
  let [pagelimittopdocs] = useState(12);

  let [productsamount, setproductsamount] = useState(0);
  let [serviceamount, setserviceamount] = useState(0);
  let [systemsamount, setsystemsamount] = useState(0);
  let [pagesamount, setpagesamount] = useState(0);

  let [listtoggle, setlisttoggle] = useState(false);
  const Localemap = {
    'da-dk': 'da_arr',
    'de-at': 'de_arr',
    'de-ch': 'de_arr',
    'de-de': 'de_arr',
    'en-at': 'en_arr',
    'x-default': 'en_arr',
    'en-ca': 'en_arr',
    'en-gb': 'en_arr',
    'en-ie': 'en_arr',
    'en-us': 'en_us_arr',
    es: 'es_arr',
    'es-es': 'es_arr',
    'es-us': 'es_arr',
    'fi-fi': 'fi_arr',
    'fr-be': 'fr_arr',
    'fr-ca': 'fr_arr',
    'fr-fr': 'fr_arr',
    'fr-ch': 'fr_arr',
    'fr-lu': 'fr_arr',
    'it-ch': 'it_arr',
    en: 'en_arr',
    'it-it': 'it_arr',
    'nb-no': 'nb_arr',
    'nl-nl': 'nl_arr',
    'nl-be': 'nl_arr',
    'pl-pl': 'pl_arr',
    'pt-br': 'pt_arr',
    'pt-pt': 'pt_arr',
    'sv-se': 'sv_arr',
  };
  let syslink = '/systems/';
  if (Links.data[Localemap[para.count]]) {
    syslink = Links.data[Localemap[para.count]][8];
  }

  if (para.count == 'en-at') {
    syslink = '/balustrades/systems/';
  }

  const translations = {
    'da-dk': 'Skriv her...',
    'de-at': 'Hier tippen...',
    'de-ch': 'Hier tippen...',
    'de-de': 'Hier tippen...',
    'en-at': 'Type here...',
    'x-default': 'Type here...',
    'en-ca': 'Type here...',
    'en-gb': 'Type here...',
    'en-ie': 'Type here...',
    'en-us': 'Type here...',
    en: 'Type here...',
    es: 'Escribe aquí...',
    'es-es': 'Escribe aquí...',
    'es-us': 'Escribe aquí...',
    'fi-fi': 'Kirjoita tähän...',
    'fr-be': 'Tapez ici...',
    'fr-ca': 'Tapez ici...',
    'fr-ch': 'Tapez ici...',
    'fr-lu': 'Tapez ici...',
    'it-ch': 'Digita qui...',
    'it-it': 'Digita qui...',
    'nb-no': 'Skriv her...',
    'nl-nl': 'Typ hier...',
    'nl-be': 'Typ hier...',
    'pl-pl': 'Wpisz tutaj...',
    'pt-br': 'Digite aqui...',
    'pt-pt': 'Digite aqui...',
    'sv-se': 'Skriv här...',
  };
  const locale = para.count;
  const translatedTextTypeHere = translations[locale] || translations['x-default'];

  if (para.count) {
    la = para.count;
    if (la.match('-')) {
      arrla = la.split('-');
      if (arrla[1]) {
        arrla[1] = arrla[1].toUpperCase();
        arrla1 = arrla[0] + '-' + arrla[1];
      }
    } else {
      arrla1 = para.count;
    }
  } else {
    la = 'en';
  }

  let [finalprods, setfinalprods] = useState();
  let [finalsites, setfinalsites] = useState();
  let [finaldops, setfinaldops] = useState();
  let [searchwords, setsearchwords] = useState('');

  useEffect(() => {
    setsearchwords(para.searchterm);
  }, [props.searchterm]);
  useEffect(() => {
    returnproductpagebtns();
  }, [finalprods, currentPage]);

  useEffect(() => {
    searchproducts();
  }, [currentPage]);
  useEffect(() => {
    returndocspagebtns();
  }, [finaldops, currentDocsPage]);
  useEffect(() => {
    searchdocs();
  }, [currentDocsPage]);
  useEffect(() => {
    const timer = setTimeout(() => {
      if (searchwords) {
        setCurrentPage(0);
        setCurrentDocsPage(0);
        searchproducts(searchwords);
        searchdocs(searchwords);
        searchsites(searchwords);
        searchsystems(searchwords);
      }
    }, 500);
    return () => clearTimeout(timer);
  }, [searchwords]);

  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 1000);
    return () => clearTimeout(timeout);
  }, [finaldops, finalsites, finalprods]);

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  let [pagetranslations, setpagetranslations] = useState({ data: [] });

  useEffect(() => {
    if (para.searchterm) {
      let searchwordinput = para.searchterm;
      searchwordinput = searchwordinput.replace('%20', ' ');

      searchWord = searchwordinput;
      document.getElementById('searchinput').value = searchwordinput;
    }

    searchdocs();
    searchproducts();
    searchsystems();
    searchsites();

    if (props.searchpage) {
      setlisttoggle(true);
      if (document.getElementById('searchLogo')) {
        document.getElementById('searchLogo').style = 'display:none';
      }

      if (document.querySelector('.Searchresultspage-wrap')) {
        document.querySelector('.Searchresultspage-wrap').style = 'box-shadow: 0px 0px 0px black';
      }
    }

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=GatheringWordTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslations(json);
      });
  }, []);

  useEffect(() => {
    returnproducts();
  }, [pagelimitbot, pagelimittop]);

  async function searchproducts() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-products/search';
    const apiKey = '3dc89833d80b98094d113cc0e4db06a4fcfd3011312fdae608b0e02a3e374cbc';
    const perPage = 12;

    const isArticleNumber = /^\d{6}/.test(searchwords);
    let searchParams = { q: searchwords, offset: currentPage * perPage, limit: perPage };

    if (isArticleNumber) {
      searchParams.filter = `ArticleNumber = ${searchwords}`;
    }

    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(searchParams),
      });

      let data = await response.json();

      if (data.hits.length === 0 && isArticleNumber) {
        delete searchParams.filters;
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({ q: searchwords, offset: 0, limit: 30 }),
        });

        data = await response.json();
      }

      setproducthits(data.estimatedTotalHits);

      let randomNumber = Math.floor(Math.random() * 70) + 1;
      data.estimatedTotalHits != 1000
        ? setproductsamount(data.estimatedTotalHits)
        : setproductsamount(data.estimatedTotalHits - randomNumber);
      setfinalprods(data.hits);
      returnproducts(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function searchdocs() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-downloads/search';
    const apiKey = '3dc89833d80b98094d113cc0e4db06a4fcfd3011312fdae608b0e02a3e374cbc';
    const perPage = 12;

    const isArticleNumber = /^\d{6}/.test(searchwords);
    let searchParams = { q: searchwords, offset: currentDocsPage * perPage, limit: 12 };

    if (isArticleNumber) {
      console.log('YAARTNBR');
      searchParams.filter = `SearchCategory1 = ${searchwords}`;
    }

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify(searchParams),
      });

      const data = await response.json();

      if (data.hits.length === 0 && isArticleNumber) {
        delete searchParams.filters;
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey}`,
          },
          body: JSON.stringify({ q: searchwords, offset: 0, limit: 30 }),
        });

        data = await response.json();
      }

      setserviceamount(data.estimatedTotalHits);
      setdocshits(data.estimatedTotalHits);
      setfinaldops(data.hits);
      returndocs(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function searchsystems() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-system/search';
    const apiKey = '3dc89833d80b98094d113cc0e4db06a4fcfd3011312fdae608b0e02a3e374cbc';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey}`,
        },
        body: JSON.stringify({ q: searchwords, offset: 0, limit: 30 }),
      });

      const data = await response.json();

      setsystemsamount(data.estimatedTotalHits);
      setfinalsystems(data.hits);
      returnsystems(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  async function searchsites(props) {
    try {
      let wory = [];
      if (props) {
        searchWord = props;
      }

      if (searchWord.match(' ')) {
        wory = searchWord.split(' ');
      }
      let response1;
      let response2;
      let response3;
      let response4;
      if (wory.length == 0) {
        response1 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[AppearInSystems][$eq]=true&populate=CardImage3x2&filters[SearchTags][$contains]=' +
            searchWord,
          requestOptions,
        );
        response2 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/projects?locale=' +
            arrla1 +
            '&filters[SearchTags][$contains]=' +
            searchWord,
          requestOptions,
        );
        response3 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage3x2&filters[SearchTags][$contains]=' +
            searchWord,
          requestOptions,
        );
        response4 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[SearchTags][$contains]=' +
            searchWord,
          requestOptions,
        );
      }
      if (wory.length > 1) {
        response1 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[AppearInSystems][$eq]=true&populate=CardImage3x2&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1],
          requestOptions,
        );
        response2 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/projects?locale=' +
            arrla1 +
            '&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1],
          requestOptions,
        );
        response3 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage3x2&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1],
          requestOptions,
        );
        response4 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1],
          requestOptions,
        );
      }
      if (wory.length > 2) {
        response1 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[AppearInSystems][$eq]=true&populate=CardImage3x2&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1] +
            '&filters[$and][2][SearchTags][$contains]=' +
            wory[2],
          requestOptions,
        );
        response2 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/projects?locale=' +
            arrla1 +
            '&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1] +
            '&filters[$and][2][SearchTags][$contains]=' +
            wory[2],
          requestOptions,
        );
        response3 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/systems?locale=' +
            arrla1 +
            '&populate=CardImage3x2&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1] +
            '&filters[$and][2][SearchTags][$contains]=' +
            wory[2],
          requestOptions,
        );
        response4 = fetch(
          'https://strapi-qrailing.azurewebsites.net/api/seitenindices?locale=' +
            arrla1 +
            '&filters[$and][0][SearchTags][$contains]=' +
            wory[0] +
            '&filters[$and][1][SearchTags][$contains]=' +
            wory[1] +
            '&filters[$and][2][SearchTags][$contains]=' +
            wory[2],
          requestOptions,
        );
      }

      const [json1, json2, json3, json4] = await Promise.all([
        response1.then((res) => res.json()),
        response2.then((res) => res.json()),
        response3.then((res) => res.json()),
        response4.then((res) => res.json()),
      ]);

      const arra1 = json1;
      const arra2 = json2;
      const arra3 = json3;
      const arra4 = json4;
      const finalarr = [arra1, arra2, arra3, arra4];

      setfinalsites(finalarr);

      setpagesamount(json2.data.length);
    } catch (error) {}
  }

  function returnproducts() {
    if (finalprods) {
      if (!listtoggle) {
        setlisttoggle(true);
      }

      return (
        <>
          {finalprods &&
            finalprods.map((item) => {
              return (
                <>
                  <a className="product-cardy-link" href={item.Shoplink} target="_blank">
                    <div className={`product-cardy ${animate ? 'animateCard' : ''}`}>
                      <img
                        width="200"
                        height={200}
                        alt={'product-image-' + item.ArticleNumber}
                        src={item.CardImageUrl}
                      />
                      <h3>{item.Name}</h3>
                      <span>{item.ArticleNumber}</span>
                    </div>
                  </a>
                </>
              );
            })}
        </>
      );
    }
  }

  function returndocs() {
    if (finaldops) {
      if (!listtoggle) {
        setlisttoggle(true);
      }
      return (
        <>
          <div className="ServiceSearch-Downloads-inner">
            {finaldops &&
              finaldops.map((item, index) => {
                if (index >= pagelimitbotdocs && index <= pagelimittopdocs) {
                  return (
                    <>
                      {!item.file && (
                        <a
                          target="_blank"
                          hrefLang={para.count}
                          href={
                            item.Category == 'Product Performance Sheet'
                              ? 'https://strapiasi3pstore.blob.core.windows.net/propers/' + item.FileName
                              : 'https://strapiasi3pstore.blob.core.windows.net/downloads-files/' + item.FileName
                          }
                        >
                          <div className={`docs-card ${animate ? 'animateCard' : ''}`}>
                            <span>{item.DisplayName}</span>
                            <span className="Svg-ServiceSearch-icon">
                              <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M13.25 8.5V12C13.25 12.3315 13.1183 12.6495 12.8839 12.8839C12.6495 13.1183 12.3315 13.25 12 13.25H2C1.66848 13.25 1.35054 13.1183 1.11612 12.8839C0.881696 12.6495 0.75 12.3315 0.75 12V8.5C0.75 8.30109 0.829018 8.11032 0.96967 7.96967C1.11032 7.82902 1.30109 7.75 1.5 7.75C1.69891 7.75 1.88968 7.82902 2.03033 7.96967C2.17098 8.11032 2.25 8.30109 2.25 8.5V11.75H11.75V8.5C11.75 8.30109 11.829 8.11032 11.9697 7.96967C12.1103 7.82902 12.3011 7.75 12.5 7.75C12.6989 7.75 12.8897 7.82902 13.0303 7.96967C13.171 8.11032 13.25 8.30109 13.25 8.5ZM6.46938 9.03063C6.53905 9.10054 6.62185 9.15602 6.71301 9.19388C6.80417 9.23173 6.90191 9.25122 7.00062 9.25122C7.09934 9.25122 7.19707 9.23173 7.28824 9.19388C7.3794 9.15602 7.4622 9.10054 7.53187 9.03063L10.0319 6.53062C10.1728 6.38973 10.2519 6.19863 10.2519 5.99937C10.2519 5.80012 10.1728 5.60902 10.0319 5.46812C9.89098 5.32723 9.69988 5.24807 9.50062 5.24807C9.30137 5.24807 9.11027 5.32723 8.96937 5.46812L7.75 6.6875V1.5C7.75 1.30109 7.67098 1.11032 7.53033 0.96967C7.38968 0.829018 7.19891 0.75 7 0.75C6.80109 0.75 6.61032 0.829018 6.46967 0.96967C6.32902 1.11032 6.25 1.30109 6.25 1.5V6.6875L5.03062 5.46938C4.88973 5.32848 4.69863 5.24932 4.49937 5.24932C4.30012 5.24932 4.10902 5.32848 3.96812 5.46938C3.82723 5.61027 3.74807 5.80137 3.74807 6.00063C3.74807 6.19988 3.82723 6.39098 3.96812 6.53188L6.46938 9.03063Z"
                                  fill="#001020"
                                />
                              </svg>
                            </span>
                          </div>
                        </a>
                      )}
                    </>
                  );
                }
              })}
          </div>
        </>
      );
    }
  }
  function returnsites() {
    if (finalsites) {
      if (!listtoggle) {
        setlisttoggle(true);
      }
      /*return(
                <>




                                {
                 finalsites[1] && finalsites[1].data.map((item,index)=>{
                        return(
                            <>
                            <a className='SearchResultSiteLinks' hrefLang={para.count} href={"/" + para.count+"/projects/"+item.attributes.Slug}>{item.attributes.Title} <span><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" transform="matrix(1 0 0 -1 0 48)" fill="#F2F3F4"/>
                            <path d="M30.0306 23.4693L25.5306 18.9693C25.3897 18.8284 25.1986 18.7492 24.9994 18.7492C24.8001 18.7492 24.609 18.8284 24.4681 18.9693C24.3272 19.1102 24.2481 19.3013 24.2481 19.5005C24.2481 19.6998 24.3272 19.8909 24.4681 20.0318L27.6875 23.2499H18.5C18.3011 23.2499 18.1103 23.3289 17.9697 23.4696C17.829 23.6102 17.75 23.801 17.75 23.9999C17.75 24.1988 17.829 24.3896 17.9697 24.5302C18.1103 24.6709 18.3011 24.7499 18.5 24.7499H27.6875L24.4694 27.9699C24.3285 28.1108 24.2493 28.3019 24.2493 28.5011C24.2493 28.7004 24.3285 28.8915 24.4694 29.0324C24.6103 29.1733 24.8014 29.2524 25.0006 29.2524C25.1999 29.2524 25.391 29.1733 25.5319 29.0324L30.0319 24.5324C30.1018 24.4626 30.1573 24.3797 30.1951 24.2885C30.2329 24.1972 30.2523 24.0994 30.2522 24.0006C30.252 23.9018 30.2324 23.804 30.1944 23.7128C30.1564 23.6216 30.1007 23.5389 30.0306 23.4693Z" fill="#001020"/>
                            </svg>
                            </span></a>
                            
                            </>
                        )
                  })
                }

                                {
                 finalsites && finalsites[3].data.map((item,index)=>{
                        return(
                            <>
                            <a className='SearchResultSiteLinks'  hrefLang={para.count} href={item.attributes.Link}>{item.attributes.Titel} <span><svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="48" height="48" rx="24" transform="matrix(1 0 0 -1 0 48)" fill="#F2F3F4"/>
                            <path d="M30.0306 23.4693L25.5306 18.9693C25.3897 18.8284 25.1986 18.7492 24.9994 18.7492C24.8001 18.7492 24.609 18.8284 24.4681 18.9693C24.3272 19.1102 24.2481 19.3013 24.2481 19.5005C24.2481 19.6998 24.3272 19.8909 24.4681 20.0318L27.6875 23.2499H18.5C18.3011 23.2499 18.1103 23.3289 17.9697 23.4696C17.829 23.6102 17.75 23.801 17.75 23.9999C17.75 24.1988 17.829 24.3896 17.9697 24.5302C18.1103 24.6709 18.3011 24.7499 18.5 24.7499H27.6875L24.4694 27.9699C24.3285 28.1108 24.2493 28.3019 24.2493 28.5011C24.2493 28.7004 24.3285 28.8915 24.4694 29.0324C24.6103 29.1733 24.8014 29.2524 25.0006 29.2524C25.1999 29.2524 25.391 29.1733 25.5319 29.0324L30.0319 24.5324C30.1018 24.4626 30.1573 24.3797 30.1951 24.2885C30.2329 24.1972 30.2523 24.0994 30.2522 24.0006C30.252 23.9018 30.2324 23.804 30.1944 23.7128C30.1564 23.6216 30.1007 23.5389 30.0306 23.4693Z" fill="#001020"/>
                            </svg></span></a>
                            
                            </>
                        )
                  })
                }
                </>
            )*/
    } else {
    }
  }

  function returnsystems() {
    if (finalsystems) {
      if (!listtoggle) {
        setlisttoggle(true);
      }

      return (
        <>
          {finalsystems.map((item) => {
            return (
              <>
                <a
                  target="_blank"
                  className="searchresults-link-systems"
                  hrefLang={para.count}
                  href={'/' + para.count + syslink + item.Slug + '/'}
                >
                  <img src={item.CardImageUrl} />
                  <h4>{item.Title}</h4>
                  <p>{item.MetaTitle}</p>
                </a>
              </>
            );
          })}
        </>
      );
    }
  }
  function returntopsystems() {
    if (finalsites) {
      if (!listtoggle) {
        setlisttoggle(true);
      }

      /*return(
            <>


                                    {
               finalsites[0] && finalsites[0].data.map((item,index)=>{
                      return(
                          <>
                          <a className='searchresults-link-systems' hrefLang={para.count} href={"/" + para.count+"/projects/"+item.attributes.Slug}>
                          <img src={item.attributes.CardImage3x2 && item.attributes.CardImage3x2.data.attributes.url}/>
                          <h6 className='Searchresults-systemtopcat-name'>{item.attributes.DisplayTitle}</h6></a>
                          
                          </>
                      )
                })
              }
              

            </>
        )*/
    } else {
    }
  }

  function returnproductpagebtns() {
    let buttons = [];
    if (producthits > 12) {
      let pages = Math.ceil(producthits / 12); // calculate the number of pages
      let startPage = Math.max(0, currentPage - 3); // start from the current page minus 3
      let endPage = Math.min(pages, startPage + 7); // end at the start page plus 7

      // Adjust if we're at the start or end of the pages
      if (currentPage < 4) {
        endPage = Math.min(pages, 7);
      }
      if (currentPage > pages - 5) {
        startPage = Math.max(0, pages - 7);
      }

      // If startPage is not the first page, add a button for the first page and an ellipsis
      if (startPage > 0) {
        buttons.push(
          <button
            className={currentPage === 0 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => {
              setCurrentPage(0);
              if (document.querySelector('.Searchpage-filter-wrap')) {
                document.querySelector('.Searchpage-filter-wrap').scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            1
          </button>,
        );
        buttons.push(<span className="points-divider-search">...</span>);
      }

      for (let i = startPage; i < endPage; i++) {
        buttons.push(
          <button
            className={currentPage === i ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => {
              setCurrentPage(i);
              if (document.querySelector('.Searchpage-filter-wrap')) {
                document.querySelector('.Searchpage-filter-wrap').scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            {i + 1}
          </button>,
        );
      }

      // If endPage is not the last page, add an ellipsis and a button for the last page
      if (endPage < pages) {
        buttons.push(<span className="points-divider-search">...</span>);
        buttons.push(
          <button
            className={currentPage === pages - 1 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => setCurrentPage(pages - 1)}
          >
            {pages}
          </button>,
        );
      }
    }

    setbtnsproduct(buttons);
  }
  function returndocspagebtns() {
    let buttons = [];
    if (docshits > 12) {
      let pages = Math.ceil(docshits / 12); // calculate the number of pages
      let startPage = Math.max(0, currentDocsPage - 3); // start from the current page minus 3
      let endPage = Math.min(pages, startPage + 7); // end at the start page plus 7

      // Adjust if we're at the start or end of the pages
      if (currentDocsPage < 4) {
        endPage = Math.min(pages, 7);
      }
      if (currentDocsPage > pages - 5) {
        startPage = Math.max(0, pages - 7);
      }

      // If startPage is not the first page, add a button for the first page and an ellipsis
      if (startPage > 0) {
        buttons.push(
          <button
            className={currentDocsPage === 0 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => {
              setCurrentDocsPage(0);
              if (document.querySelector('.Searchresults-Downloads')) {
                document.querySelector('.Searchresults-Downloads').scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            1
          </button>,
        );
        buttons.push(<span className="points-divider-search">...</span>);
      }

      for (let i = startPage; i < endPage; i++) {
        buttons.push(
          <button
            className={currentDocsPage === i ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => {
              setCurrentDocsPage(i);
              if (document.querySelector('.Searchresults-Downloads')) {
                document.querySelector('.Searchresults-Downloads').scrollIntoView({ behavior: 'smooth' });
              }
            }}
          >
            {i + 1}
          </button>,
        );
      }

      // If endPage is not the last page, add an ellipsis and a button for the last page
      if (endPage < pages) {
        buttons.push(<span className="points-divider-search">...</span>);
        buttons.push(
          <button
            className={currentDocsPage === pages - 1 ? 'paginationbutton active_pagebtn' : 'paginationbutton'}
            onClick={() => setCurrentDocsPage(pages - 1)}
          >
            {pages}
          </button>,
        );
      }
    }

    setbtnsdocs(buttons);
  }

  return (
    <>
      <div className="Searchresultspage-wrap">
        {props.searchpage && (
          <div className="Searchresultspage-page-wrap">
            <div className="Searchresultspage-page-inner">
              {props.searchpage && (
                <div className="SearchPageHeadline">
                  <h3>
                    {productsamount + systemsamount + serviceamount + pagesamount}{' '}
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[1]
                      : 'Results available'}
                  </h3>
                </div>
              )}
              {props.searchpage && (
                <div className="SearchpageInput">
                  <label id="searchpage-label-input">
                    <input
                      onChange={(e) => {
                        setsearchwords(e.target.value);
                      }}
                      type="text"
                      id="searchinput"
                      placeholder={translatedTextTypeHere}
                    />
                    <button
                      onClick={() => {
                        document.getElementById('searchinput').value = '';
                      }}
                    >
                      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.854 12.146C12.9005 12.1925 12.9373 12.2476 12.9625 12.3083C12.9876 12.369 13.0006 12.4341 13.0006 12.4998C13.0006 12.5655 12.9876 12.6305 12.9625 12.6912C12.9373 12.7519 12.9005 12.8071 12.854 12.8535C12.8076 12.9 12.7524 12.9368 12.6917 12.962C12.631 12.9871 12.566 13.0001 12.5003 13.0001C12.4346 13.0001 12.3695 12.9871 12.3088 12.962C12.2481 12.9368 12.193 12.9 12.1465 12.8535L8.00028 8.70666L3.85403 12.8535C3.76021 12.9474 3.63296 13.0001 3.50028 13.0001C3.3676 13.0001 3.24035 12.9474 3.14653 12.8535C3.05271 12.7597 3 12.6325 3 12.4998C3 12.3671 3.05271 12.2399 3.14653 12.146L7.2934 7.99979L3.14653 3.85354C3.05271 3.75972 3 3.63247 3 3.49979C3 3.36711 3.05271 3.23986 3.14653 3.14604C3.24035 3.05222 3.3676 2.99951 3.50028 2.99951C3.63296 2.99951 3.76021 3.05222 3.85403 3.14604L8.00028 7.29291L12.1465 3.14604C12.2403 3.05222 12.3676 2.99951 12.5003 2.99951C12.633 2.99951 12.7602 3.05222 12.854 3.14604C12.9478 3.23986 13.0006 3.36711 13.0006 3.49979C13.0006 3.63247 12.9478 3.75972 12.854 3.85354L8.70715 7.99979L12.854 12.146Z"
                          fill="#001020"
                        />
                      </svg>
                    </button>
                  </label>
                  <button
                    id="searchpage-searchbtn"
                    onClick={() =>
                      window.location.replace(
                        '/' + para.count + '/search/' + document.getElementById('searchinput').value.toString(),
                      )
                    }
                  >
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[0]
                      : 'Search'}
                  </button>
                </div>
              )}
            </div>
          </div>
        )}

        <div
          className="Searchresults-container"
          id={props.searchpage ? 'Searchpage-inner-id' : 'Searchresult-inner-id'}
        >
          <div className="Searchresults-search" id="searchbar">
            <Link id="searchLogo" hrefLang={para.count} to={'/' + para.count + '/'}>
              <img
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                width="72"
                height={'72'}
                alt="Q-railing logo"
              />
            </Link>
            {!props.searchpage && (
              <div className="Searchresults-searchbar-inner">
                <img
                  alt="Link icon Search-responsive"
                  src={'https://q-railing.s3.eu-central-1.amazonaws.com/icons/lupe_black.png'}
                  width="20"
                  height="20"
                />
                <input
                  onChange={(e) => {
                    searchWord = e.target.value.toLowerCase();
                    {
                      searchproducts();
                      searchsites();
                      searchdocs();
                    }
                  }}
                  type="text"
                  id="searchinput"
                  placeholder={translatedTextTypeHere}
                />
              </div>
            )}

            <div className="Searchresults-searchbar-buttons">
              <button
                onClick={() =>
                  window.location.replace(
                    '/' + para.count + '/search/' + document.getElementById('searchinput').value.toString(),
                  )
                }
              >
                Search
              </button>
              <button onClick={() => props.searchtoggle(false)}>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.281 14.2193C15.3507 14.289 15.406 14.3717 15.4437 14.4628C15.4814 14.5538 15.5008 14.6514 15.5008 14.7499C15.5008 14.8485 15.4814 14.9461 15.4437 15.0371C15.406 15.1281 15.3507 15.2109 15.281 15.2806C15.2114 15.3502 15.1286 15.4055 15.0376 15.4432C14.9465 15.4809 14.849 15.5003 14.7504 15.5003C14.6519 15.5003 14.5543 15.4809 14.4632 15.4432C14.3722 15.4055 14.2895 15.3502 14.2198 15.2806L8.00042 9.06024L1.78104 15.2806C1.64031 15.4213 1.44944 15.5003 1.25042 15.5003C1.05139 15.5003 0.860523 15.4213 0.719792 15.2806C0.579062 15.1398 0.5 14.949 0.5 14.7499C0.5 14.5509 0.579062 14.36 0.719792 14.2193L6.9401 7.99993L0.719792 1.78055C0.579062 1.63982 0.5 1.44895 0.5 1.24993C0.5 1.05091 0.579062 0.860034 0.719792 0.719304C0.860523 0.578573 1.05139 0.499512 1.25042 0.499512C1.44944 0.499512 1.64031 0.578573 1.78104 0.719304L8.00042 6.93962L14.2198 0.719304C14.3605 0.578573 14.5514 0.499512 14.7504 0.499512C14.9494 0.499512 15.1403 0.578573 15.281 0.719304C15.4218 0.860034 15.5008 1.05091 15.5008 1.24993C15.5008 1.44895 15.4218 1.63982 15.281 1.78055L9.06073 7.99993L15.281 14.2193Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>

          {props.searchpage && (
            <div className="Searchpage-filter-wrap">
              <button onClick={() => setfilterword('all')} className={filterword == 'all' ? 'activefilterbtn' : ''}>
                {pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.FreeData1.Searchpage[2] : 'All'}{' '}
              </button>
              <button
                onClick={() => setfilterword('products')}
                className={filterword == 'products' ? 'activefilterbtn' : ''}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.FreeData1.Searchpage[3]
                  : 'Products'}{' '}
                <span>({productsamount})</span>
              </button>
              <button
                onClick={() => setfilterword('systems')}
                className={filterword == 'systems' ? 'activefilterbtn' : ''}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.FreeData1.Searchpage[4]
                  : 'Systems'}{' '}
                <span>({systemsamount})</span>
              </button>
              <button
                onClick={() => setfilterword('service')}
                className={filterword == 'service' ? 'activefilterbtn' : ''}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.FreeData1.Searchpage[5]
                  : 'Service'}{' '}
                <span>({serviceamount})</span>
              </button>
              <button
                id={'hiddenid'}
                onClick={() => setfilterword('pages')}
                className={filterword == 'pages' ? 'activefilterbtn' : ''}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.FreeData1.Searchpage[6]
                  : 'Pages'}{' '}
                <span>({pagesamount})</span>
              </button>
            </div>
          )}

          {props.searchpage && (
            <div className="Searchresults-results">
              {(filterword == 'all' || filterword == 'products') && (
                <div className="Searchresults-Products" id={producthits === 0 && 'hiddenid'}>
                  <h2>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[3]
                      : 'Products'}{' '}
                    <span>{productsamount != 0 ? productsamount : ''}</span>
                  </h2>
                  <div className="Searchresults-Products-Wrap">
                    {returnproducts()}
                    {producthits == 0 && <p>No Products found</p>}
                  </div>
                  {finalprods && (
                    <>
                      {producthits > 12 && (
                        <div className="paginationproducts">
                          <button
                            onClick={() => {
                              if (currentPage != 0) {
                                setCurrentPage(currentPage - 1);
                                if (document.querySelector('.Searchpage-filter-wrap')) {
                                  document
                                    .querySelector('.Searchpage-filter-wrap')
                                    .scrollIntoView({ behavior: 'smooth' });
                                }
                              }
                            }}
                          >
                            <svg
                              width="7"
                              height="12"
                              viewBox="0 0 7 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.53016 10.4701C6.67106 10.6109 6.75021 10.802 6.75021 11.0013C6.75021 11.2006 6.67106 11.3917 6.53016 11.5326C6.38927 11.6734 6.19817 11.7526 5.99891 11.7526C5.79965 11.7526 5.60856 11.6734 5.46766 11.5326L0.467661 6.53255C0.397741 6.46287 0.342264 6.38008 0.30441 6.28891C0.266556 6.19775 0.24707 6.10001 0.24707 6.0013C0.24707 5.90259 0.266556 5.80485 0.30441 5.71369C0.342264 5.62252 0.397741 5.53973 0.467661 5.47005L5.46766 0.470051C5.60856 0.329155 5.79965 0.25 5.99891 0.25C6.19817 0.25 6.38927 0.329155 6.53016 0.470051C6.67106 0.610947 6.75021 0.802043 6.75021 1.0013C6.75021 1.20056 6.67106 1.39165 6.53016 1.53255L2.06204 6.00068L6.53016 10.4701Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                          {btnsproduct.map((button) => {
                            return button;
                          })}

                          <button
                            onClick={() => {
                              setCurrentPage(currentPage + 1);
                              if (document.querySelector('.Searchpage-filter-wrap')) {
                                document
                                  .querySelector('.Searchpage-filter-wrap')
                                  .scrollIntoView({ behavior: 'smooth' });
                              }
                            }}
                          >
                            <svg
                              width="7"
                              height="12"
                              viewBox="0 0 7 12"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6.5306 6.5306L1.5306 11.5306C1.3897 11.6715 1.19861 11.7506 0.999348 11.7506C0.80009 11.7506 0.608994 11.6715 0.468098 11.5306C0.327201 11.3897 0.248047 11.1986 0.248047 10.9993C0.248047 10.8001 0.327201 10.609 0.468098 10.4681L4.93747 5.99997L0.469348 1.5306C0.399583 1.46083 0.344243 1.37801 0.306486 1.28686C0.26873 1.19571 0.249297 1.09801 0.249297 0.999348C0.249297 0.900686 0.26873 0.802989 0.306486 0.711837C0.344243 0.620685 0.399583 0.537863 0.469348 0.468098C0.539113 0.398333 0.621936 0.342993 0.713088 0.305236C0.80424 0.26748 0.901936 0.248047 1.0006 0.248047C1.09926 0.248047 1.19696 0.26748 1.28811 0.305236C1.37926 0.342993 1.46208 0.398333 1.53185 0.468098L6.53185 5.4681C6.60169 5.53786 6.65707 5.62072 6.69481 5.71193C6.73256 5.80313 6.75193 5.9009 6.75181 5.99961C6.7517 6.09832 6.7321 6.19603 6.69414 6.28715C6.65617 6.37827 6.6006 6.461 6.5306 6.5306Z"
                                fill="black"
                              />
                            </svg>
                          </button>
                        </div>
                      )}
                    </>
                  )}
                </div>
              )}

              {(filterword == 'all' || filterword == 'systems') && (
                <div className="Searchresults-Systems" id={systemsamount === 0 && 'hiddenid'}>
                  <h2>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[4]
                      : 'Systems'}{' '}
                    <span>{systemsamount != 0 ? systemsamount : ''}</span>
                  </h2>
                  <div className="Searchresults-Systems-Grid" id="SearchResults-topsystems">
                    {returntopsystems()}
                  </div>

                  <div className="Searchresults-Systems-Grid">{returnsystems()}</div>
                </div>
              )}

              {(filterword == 'all' || filterword == 'service') && (
                <div className="Searchresults-Downloads" id={docshits === 0 && 'hiddenid'}>
                  <h2>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[5]
                      : 'Service'}{' '}
                    <span>{serviceamount != 0 ? serviceamount : ''}</span>
                  </h2>
                  {returndocs()}

                  {docshits > 12 && (
                    <div className="paginationproducts">
                      <button
                        onClick={() => {
                          if (currentDocsPage != 0) {
                            setCurrentDocsPage(currentDocsPage - 1);
                            if (document.querySelector('.Searchresults-Downloads')) {
                              document.querySelector('.Searchresults-Downloads').scrollIntoView({ behavior: 'smooth' });
                            }
                          }
                        }}
                      >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.53016 10.4701C6.67106 10.6109 6.75021 10.802 6.75021 11.0013C6.75021 11.2006 6.67106 11.3917 6.53016 11.5326C6.38927 11.6734 6.19817 11.7526 5.99891 11.7526C5.79965 11.7526 5.60856 11.6734 5.46766 11.5326L0.467661 6.53255C0.397741 6.46287 0.342264 6.38008 0.30441 6.28891C0.266556 6.19775 0.24707 6.10001 0.24707 6.0013C0.24707 5.90259 0.266556 5.80485 0.30441 5.71369C0.342264 5.62252 0.397741 5.53973 0.467661 5.47005L5.46766 0.470051C5.60856 0.329155 5.79965 0.25 5.99891 0.25C6.19817 0.25 6.38927 0.329155 6.53016 0.470051C6.67106 0.610947 6.75021 0.802043 6.75021 1.0013C6.75021 1.20056 6.67106 1.39165 6.53016 1.53255L2.06204 6.00068L6.53016 10.4701Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                      {btnsdocs.map((button) => {
                        return button;
                      })}

                      <button
                        onClick={() => {
                          setCurrentDocsPage(currentDocsPage + 1);

                          if (document.querySelector('.Searchresults-Downloads')) {
                            document.querySelector('.Searchresults-Downloads').scrollIntoView({ behavior: 'smooth' });
                          }
                        }}
                      >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M6.5306 6.5306L1.5306 11.5306C1.3897 11.6715 1.19861 11.7506 0.999348 11.7506C0.80009 11.7506 0.608994 11.6715 0.468098 11.5306C0.327201 11.3897 0.248047 11.1986 0.248047 10.9993C0.248047 10.8001 0.327201 10.609 0.468098 10.4681L4.93747 5.99997L0.469348 1.5306C0.399583 1.46083 0.344243 1.37801 0.306486 1.28686C0.26873 1.19571 0.249297 1.09801 0.249297 0.999348C0.249297 0.900686 0.26873 0.802989 0.306486 0.711837C0.344243 0.620685 0.399583 0.537863 0.469348 0.468098C0.539113 0.398333 0.621936 0.342993 0.713088 0.305236C0.80424 0.26748 0.901936 0.248047 1.0006 0.248047C1.09926 0.248047 1.19696 0.26748 1.28811 0.305236C1.37926 0.342993 1.46208 0.398333 1.53185 0.468098L6.53185 5.4681C6.60169 5.53786 6.65707 5.62072 6.69481 5.71193C6.73256 5.80313 6.75193 5.9009 6.75181 5.99961C6.7517 6.09832 6.7321 6.19603 6.69414 6.28715C6.65617 6.37827 6.6006 6.461 6.5306 6.5306Z"
                            fill="black"
                          />
                        </svg>
                      </button>
                    </div>
                  )}
                  {docshits == 0 && <p>No Documents found</p>}
                </div>
              )}

              {(filterword == 'all' || filterword == 'pages') && (
                <div className="Searchresults-Sites" id={pagesamount < 10 && 'hiddenid'}>
                  <h2>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[6]
                      : 'Pages'}{' '}
                    <span>{pagesamount != 0 ? pagesamount : ''}</span>
                  </h2>

                  {returnsites()}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default SearchresultsMeili;
