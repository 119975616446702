import React, { useEffect, useState } from 'react';
import { Link, useParams, useLocation } from 'react-router-dom';
import './Projectblock.css';
import LinkData from './Links.json';

function Projectblock(props) {
  let param = useParams();
  let para = useParams();
  let [systemarr, setsystemarr] = useState({ data: [] });
  let [systemarr2, setsystemarr2] = useState({ data: [] });
  let [filtertoggle, setfiltertoggle] = useState('all');
  let [count, setcount] = useState(4);
  let [page] = useState(0);
  let [projectnumber, setprojectnumber] = useState(0);
  let [linkarr, setlinkarr] = useState([]);
  let [pagetranslations, setpagetranslations] = useState({ data: [] });
  let arry = [];
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  let la;
  let arrla = [];
  let arrla1 = '';

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=ProjectGridTranslation',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslations(json);
      });
    decidethepaths();
    rendernormal();
  }, []);

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];

      if (para.count) {
        val = [para.count, 'en'];
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }
      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }
  const [animate, setAnimate] = useState(false);
  useEffect(() => {
    setAnimate(true);
    const timeout = setTimeout(() => setAnimate(false), 1000);
    return () => clearTimeout(timeout);
  }, [systemarr]);

  useEffect(() => {
    rerenderprods2();
  }, [count]);

  useEffect(() => {
    rerenderprods2();
  }, [systemarr]);

  function rendernormal() {
    let sorter = 'sort[0]=Priority';
    if (props.usage == 'architect') {
      sorter = 'sort[0]=ArchitectPriority';
    }
    const cachedData = localStorage.getItem('cachedDataProjects');
    if (cachedData && !props.url) {
      let now = new Date();
      let HitArray = [];
      let json = JSON.parse(cachedData).json;
      if (props.inspiration) {
        let Localehit;
        if (param.count.match('-')) {
          Localehit = para.count.split('-')[1].toLocaleUpperCase();
        } else {
          Localehit = para.count.toLocaleUpperCase();
        }
        if (param.count == 'en-gb' || param.count == 'en-ie') {
          Localehit = 'UK';
        }
        if (param.count == 'en-at') {
          Localehit = 'EE';
        }
        json.data.map((item) => {
          if (item.attributes.ProjectLocale == Localehit) {
            HitArray.push(item);
          }
        });

        // Filter out items from json.data that are already in HitArray
        let filteredData = json.data.filter((item) => item.attributes.ProjectLocale !== Localehit);

        // Combine HitArray with the filtered json.data
        let combinedArray = [...HitArray, ...filteredData];

        // Update json.data with the combined array
        json.data = combinedArray;
      }

      setsystemarr(json);
      setprojectnumber(JSON.parse(cachedData).json.data.length);

      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem('cachedDataProjects');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/projects?pagination[pageSize]=250&locale=' +
          arrla1 +
          '&populate=CardImage,CardImage3x2,CardImageBig&' +
          sorter,
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          let HitArray = [];

          if (props.inspiration) {
            let Localehit;
            if (param.count.match('-')) {
              Localehit = para.count.split('-')[1].toLocaleUpperCase();
            } else {
              Localehit = para.count.toLocaleUpperCase();
            }
            if (param.count == 'en-gb' || param.count == 'en-ie') {
              Localehit = 'UK';
            }
            if (param.count == 'en-at') {
              Localehit = 'EE';
            }
            json.data.map((item) => {
              if (item.attributes.ProjectLocale == Localehit) {
                HitArray.push(item);
              }
            });

            // Filter out items from json.data that are already in HitArray
            let filteredData = json.data.filter((item) => item.attributes.ProjectLocale !== Localehit);

            // Combine HitArray with the filtered json.data
            let combinedArray = [...HitArray, ...filteredData];

            // Update json.data with the combined array
            json.data = combinedArray;
          }
          setsystemarr(json);
          setprojectnumber(json.data.length);
          localStorage.setItem(
            'cachedDataProjects',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        })
        .then(() => {
          if (systemarr) {
            rerenderprods2();
          }
        });
    }
  }

  function rerenderprods2() {
    if (systemarr.data) {
      for (let i = 0; i <= count; i++) {
        try {
          if (systemarr.data[i]) {
            arry.push(systemarr.data[i]);
          }
        } catch {}
      }

      let imv = { data: [page, arry] };
      setsystemarr2(imv);
    } else {
      console.log('not yet');
    }
  }

  function rerendering() {
    if (systemarr2.data.length > 0) {
      let thevals;
      let finalarra = [];
      for (let i = 0; i < systemarr2.data[1].length; i = i + 5) {
        if (i < systemarr2.data[1].length) {
          thevals = [
            systemarr2.data[1][i],
            systemarr2.data[1][i + 1],
            systemarr2.data[1][i + 2],
            systemarr2.data[1][i + 3],
            systemarr2.data[1][i + 4],
          ];
          if (thevals) {
            finalarra.push(thevals);
          }
        }
      }
      return <>{renderthestuff(finalarra)}</>;
    }
    return <></>;
  }

  function loadfilter(prop) {
    if (prop) {
      setfiltertoggle(prop);
      if (prop != 'all') {
        let sorter = 'sort[0]=Priority';
        if (props.usage == 'architect') {
          sorter = 'sort[0]=ArchitectPriority&sort[1]=Priority';
        }
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/projects?pagination[pageSize]=200&locale=' +
            arrla1 +
            '&filters[' +
            prop +
            '][$eq]=true&populate=CardImage,CardImage3x2,CardImageBig&' +
            sorter,
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            setsystemarr(json);
            setprojectnumber(json.data.length);
          })
          .then(() => {
            if (systemarr) {
              rerenderprods2();
            }
          });
      } else {
        let sorter = 'sort[0]=Priority';
        if (props.usage == 'architect') {
          sorter = 'sort[0]=ArchitectPriority&sort[1]=Priority';
        }
        fetch(
          'https://strapi-qrailing.azurewebsites.net/api/projects?pagination[pageSize]=200&locale=' +
            arrla1 +
            '&populate=CardImage,CardImage3x2,CardImageBig&' +
            sorter,
          requestOptions,
        )
          .then((res) => res.json())
          .then((json) => {
            let HitArray = [];
            let Localehit;
            if (param.count.match('-')) {
              Localehit = para.count.split('-')[1].toLocaleUpperCase();
            } else {
              Localehit = para.count.toLocaleUpperCase();
            }
            if (param.count == 'en-gb' || param.count == 'en-ie') {
              Localehit = 'UK';
            }
            if (param.count == 'en-at') {
              Localehit = 'EE';
            }
            json.data.map((item) => {
              if (item.attributes.ProjectLocale == Localehit) {
                HitArray.push(item);
              }
            });

            // Filter out items from json.data that are already in HitArray
            let filteredData = json.data.filter((item) => item.attributes.ProjectLocale !== Localehit);

            // Combine HitArray with the filtered json.data
            let combinedArray = [...HitArray, ...filteredData];

            // Update json.data with the combined array
            json.data = combinedArray;

            console.log(HitArray);

            setsystemarr(json);
            setprojectnumber(json.data.length);
          })
          .then(() => {
            if (systemarr) {
              rerenderprods2();
            }
          });
      }
    }
  }

  function renderprojectflags(prop, county) {
    let renderarr = [];
    if (pagetranslations.data.length > 0) {
      prop.attributes.IndoorOutdoor == 'Indoor'
        ? renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[13])
        : prop.attributes.IndoorOutdoor == 'Outdoor'
          ? renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[14])
          : renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[15]);
      prop.attributes.Mount == 'Top mount'
        ? renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[16])
        : prop.attributes.Mount == 'Fasia mount'
          ? renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[17])
          : renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[18]);
      prop.attributes.balconies && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[2]);
      prop.attributes.stairs && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[3]);
      prop.attributes.glassrailing && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[4]);
      prop.attributes.frenchbalcony && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[5]);
      prop.attributes.handrail && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[6]);
      prop.attributes.posts && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[7]);
      prop.attributes.terrace && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[8]);
      prop.attributes.public && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[9]);
      prop.attributes.private && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[10]);
      prop.attributes.commercial && renderarr.push(pagetranslations.data[0].attributes.PageTranslations.data[11]);
    }

    return (
      <span className="Projectgrid-flags">
        {renderarr.map((item, index) => {
          if (index < 5 && document.body.clientWidth > 1024 && !county) {
            return <span className="Projectsgrid-flag">{item}</span>;
          }
          if (index < 3 && document.body.clientWidth > 1024 && county) {
            return <span className="Projectsgrid-flag">{item}</span>;
          }

          if (index < 3 && document.body.clientWidth < 1024) {
            return <span className="Projectsgrid-flag">{item}</span>;
          }
        })}
      </span>
    );
  }

  function renderthestuff(props) {
    if (props) {
      return (
        <>
          {props.map((statss, index) => (
            <>
              {index <= count && linkarr.length > 0 && (
                <div className={`projectblock-page ${animate ? 'animateCard' : ''}`}>
                  <div className="projectblock-row1">
                    <div className="projectblock-col1">
                      <Link
                        hrefLang={param.count}
                        id="proj-links"
                        className={'statss' + index}
                        to={'/' + param.count + '/projects/' + statss[0].attributes.Slug + '/'}
                      >
                        <img
                          className="project-img"
                          width="312"
                          height="312"
                          alt={'projectimage' + index + 1}
                          src={statss[0].attributes.CardImage.data.attributes.url}
                        />
                        <div className="proj-links-inner">
                          <p>
                            {statss[0].attributes.Title} {renderprojectflags(statss[0], true)}
                          </p>
                        </div>
                      </Link>
                      {statss[1] && (
                        <Link
                          id="proj-links"
                          className={'statss' + index}
                          to={'/' + param.count + '/projects/' + statss[1].attributes.Slug + '/'}
                        >
                          <img
                            className="project-img"
                            width="312"
                            height="312"
                            alt={'projectimage' + index + 2}
                            src={statss[1].attributes.CardImage.data.attributes.url}
                          />
                          <div className="proj-links-inner">
                            <p>
                              {statss[1].attributes.Title} {renderprojectflags(statss[1], true)}
                            </p>
                          </div>
                        </Link>
                      )}
                    </div>
                    <div className="projectblock-col2">
                      {statss[2] && (
                        <Link
                          id="proj-links"
                          className={'statss' + index + ' linkfix-proj'}
                          to={'/' + param.count + '/projects/' + statss[2].attributes.Slug + '/'}
                        >
                          <img
                            className="project-img"
                            width="760"
                            height="648"
                            alt={'projectimage' + index + 3}
                            src={statss[2].attributes.CardImageBig.data.attributes.url}
                          />
                          <div className="proj-links-inner" id="proj-links-inner-spezfix">
                            <p>
                              {statss[2].attributes.Title} {renderprojectflags(statss[2])}
                            </p>
                          </div>
                        </Link>
                      )}
                    </div>
                  </div>
                  <div className="projectblock-row2">
                    {statss[3] && (
                      <Link
                        id="proj-links"
                        className={'statss' + index}
                        to={'/' + param.count + '/projects/' + statss[3].attributes.Slug + '/'}
                      >
                        <img
                          className="project-img"
                          width="536"
                          height="376"
                          alt={'projectimage' + index + 4}
                          src={statss[3].attributes.CardImage3x2.data.attributes.url}
                        />
                        <div className="proj-links-inner">
                          <p>
                            {statss[3].attributes.Title}
                            {renderprojectflags(statss[3], true)}
                          </p>
                        </div>
                      </Link>
                    )}
                    {statss[4] && (
                      <Link
                        id="proj-links"
                        className={'statss' + index}
                        to={'/' + param.count + '/projects/' + statss[4].attributes.Slug + '/'}
                      >
                        <img
                          className="project-img"
                          width="536"
                          height="376"
                          alt={'projectimage' + index + 5}
                          src={statss[4].attributes.CardImage3x2.data.attributes.url}
                        />
                        <div className="proj-links-inner">
                          <p>
                            {statss[4].attributes.Title} {renderprojectflags(statss[4], true)}
                          </p>
                        </div>
                      </Link>
                    )}
                  </div>
                </div>
              )}
            </>
          ))}
        </>
      );
    }
  }
  function countmax() {
    setcount((count = count + 5));
    if (count > systemarr.data.length) {
      setcount(systemarr.data.length);
    }
  }
  return (
    <>
      {systemarr.data.length > 0 && (
        <div className="projectblock-outer-wrap" id={props.color == 'grey' && 'GreyProjectGrid'}>
          <div className="Projects-Wrapper">
            <div className="projectblock-head">
              <div className="projectblock-text">
                <h2>
                  {pagetranslations.data.length > 0
                    ? pagetranslations.data[0].attributes.PageTranslations.data[0]
                    : 'Projects'}{' '}
                  <span>{projectnumber}</span>
                </h2>
              </div>
            </div>
            <div className="projectblock-filter">
              <button
                className={filtertoggle == 'all' ? 'activegridbtn' : ''}
                id="projectblock-filter-all"
                onClick={() => loadfilter('all')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[1]
                  : 'All'}
              </button>
              <button
                className={filtertoggle == 'balconies' ? 'activegridbtn' : ''}
                id="projectblock-filter-balconies"
                onClick={() => loadfilter('balconies')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[2]
                  : 'Balconies'}
              </button>
              <button
                className={filtertoggle == 'stairs' ? 'activegridbtn' : ''}
                id="projectblock-filter-stairs"
                onClick={() => loadfilter('stairs')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[3]
                  : 'Stairs'}
              </button>
              <button
                className={filtertoggle == 'glassrailing' ? 'activegridbtn' : ''}
                id="projectblock-filter-glassrailing"
                onClick={() => loadfilter('glassrailing')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[4]
                  : 'Glass railing'}
              </button>
              <button
                className={filtertoggle == 'frenchbalcony' ? 'activegridbtn' : ''}
                id="projectblock-filter-frenchbalcony"
                onClick={() => loadfilter('frenchbalcony')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[5]
                  : 'French balcony'}
              </button>
              <button
                className={filtertoggle == 'handrail' ? 'activegridbtn' : ''}
                id="projectblock-filter-handrail"
                onClick={() => loadfilter('handrail')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[6]
                  : 'Handrail'}
              </button>
              <button
                className={filtertoggle == 'posts' ? 'activegridbtn' : ''}
                id="projectblock-filter-posts"
                onClick={() => loadfilter('posts')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[7]
                  : 'Posts'}
              </button>
              <button
                className={filtertoggle == 'terrace' ? 'activegridbtn' : ''}
                id="projectblock-filter-terrace"
                onClick={() => loadfilter('terrace')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[8]
                  : 'Terrace'}
              </button>
              <button
                className={filtertoggle == 'public' ? 'activegridbtn' : ''}
                id="projectblock-filter-public"
                onClick={() => loadfilter('public')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[9]
                  : 'Public'}
              </button>
              <button
                className={filtertoggle == 'private' ? 'activegridbtn' : ''}
                id="projectblock-filter-private"
                onClick={() => loadfilter('private')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[10]
                  : 'Private'}
              </button>
              <button
                className={filtertoggle == 'commercial' ? 'activegridbtn' : ''}
                id="projectblock-filter-commercial"
                onClick={() => loadfilter('commercial')}
              >
                {pagetranslations.data.length > 0
                  ? pagetranslations.data[0].attributes.PageTranslations.data[11]
                  : 'Commercial'}
              </button>
            </div>
            <div className="Projects-image-cards">
              {rerendering()}

              {renderthestuff()}
            </div>

            <div className="morebtn1">
              <div></div>
              <div className="morebtn-btn">
                <button
                  onClick={() => {
                    countmax();
                    rerenderprods2();
                  }}
                >
                  {pagetranslations.data.length > 0
                    ? pagetranslations.data[0].attributes.PageTranslations.data[12]
                    : 'More Projects'}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Projectblock;
