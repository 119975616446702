import { lazy } from 'react';

import Mainpage from '../../../components/Mainpage';
import Systems from '../../../components/Systems';
import Service from '../../../components/Service';
import '../../../components/includes/Header.css';

import Projectpage from '../../../components/Projectpage';
import Aboutpage from '../../../components/Aboutpage';
import Registerform from '../../../components/includes/Registerform';

import Textpage from '../../../components/Textpage';
import Jobcenter from '../../../components/Jobcenter';
import Jobcenterdetail from '../../../components/Jobcenterdetail';

import Downloads from '../../../components/Downloads';
import Contact from '../../../components/includes/Contact';
import Page404 from '../../../components/Page404';
import SearchresultsPage from '../../../components/SearchresultsPage';
import NewsOverview from '../../../components/NewsOverview';

import TrainingsOverview from '../../../components/TrainingsOverview';
import TrainingsDetail from '../../../components/TrainingsDetail';
import CatalogPage from '../../../components/CatalogPage';
import InstallerRequest from '../../../components/InstallerRequest';
import CategoriePage from '../../../components/CategoriePage';

const lazyLoadComponentRetry = (componentImport) =>
  new Promise((resolve, reject) => {
    const storageKey = `retry-lazy-refreshed${btoa(componentImport.toString())}`;
    const hasRefreshed = JSON.parse(window.sessionStorage.getItem(storageKey) || 'false');
    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(storageKey, 'false');
        if (component === undefined) {
          window.sessionStorage.setItem(storageKey, 'true');
          return window.location.reload(); // refresh the page
        }
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem(storageKey, 'true');
          window.location.reload();
        }
        reject(error); // Default error behaviour as already tried refresh
      });
  });

const VideoOverview = lazy(() => lazyLoadComponentRetry(() => import('../../../components/VideoOverview')));
const CategoryGridPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/CategoryGridPage')));
const NewsDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/NewsDetail')));
const KonfiguratorDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/KonfiguratorDetail')));
const Seopage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Seopage')));
const ProjectDetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/ProjectDetail')));
const Architect = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Architect')));
const Installer = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Installer')));
const Consumer = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Consumer')));
const Systemdetail = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Systemdetail')));
const Prefix = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Prefix')));
const Roadshow = lazy(() => lazyLoadComponentRetry(() => import('../../../components/Roadshow')));
const SystemTopCat = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SystemTopCat')));
const QdiscPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/QdiscPage')));
const SafetyPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SafetyPage')));
const SystemFinderPage = lazy(() => lazyLoadComponentRetry(() => import('../../../components/SystemFinderPage')));

export const Routing = {
  __component: Mainpage,
  __fallback: '404',
  404: {
    __component: Page404,
  },
  architect: {
    __component: Architect,
  },
  balustrades: {
    __component: Seopage,
    __$topic: 'railings',

    'balcony-railings': {
      __component: Seopage,
      __$topic: 'balcony',
    },
    'baluster-railings': {},
    'base-channels': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailBaseChannelsDisk',
    },
    'base-clamps': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailBaseClamps',
    },
    configurator: {
      __component: KonfiguratorDetail,
      ':title': {
        __component: KonfiguratorDetail,
      },
    },
    'glass-adapters': {
      __component: SystemTopCat,
      __$category: 'FilterGlassrailAdapters',
    },
    'glass-balustrades': {
      __component: Seopage,
      __$topic: 'glassrailing',
    },
    'terrace-railings': {
      __component: Seopage,
      __$topic: 'terrace-railing',
    },
    'glass-clamps-and-connectors': {
      __component: CategoriePage,
      __$shopcategory: 'CategorypageGlassClamps',
    },
    handrails: {
      __component: SystemTopCat,
      __$category: 'FilterHandrails',
    },
    infills: {
      __component: CategoriePage,
      __$shopcategory: 'CategorypageInfills',
    },
    'juliet-balcony': {
      __component: SystemTopCat,
      __$category: 'FilterJulietBalconies',
    },
    'railing-safety': {
      __component: SafetyPage,
      __$topic: 'safety',
    },
    'stainless-steel-post-railing-systems': {
      __component: SystemTopCat,
      __$category: 'FilterStainlessSteelPosts',
    },
    'stair-railings': {
      __component: Seopage,
      __$topic: 'stairs',
    },
    systems: {
      __component: Systems,
      ':systems': {
        __component: Systemdetail,
      },
    },
    test: {
      __component: SafetyPage,
      __$topic: 'stairs',
    },
    systemfinder: {
      __component: SystemFinderPage,
    },
    'fire-rated-glass-balustrades': {
      __component: Seopage,
      __$topic: 'fire-rated-glass-balustrades',
    },
  },
  categories: {
    __component: CategoryGridPage,
  },
  career: {
    __redirect: '/company/career/',
    ':joboffer': {
      __redirect: '/company/career/:joboffer/',
    },
  },
  company: {
    'about-us': {
      __component: Aboutpage,
    },
    career: {
      __component: Jobcenter,
      ':joboffer': {
        __component: Jobcenterdetail,
      },
    },
  },
  consumer: {
    __component: Consumer,
  },
  cookies: {},
  inspiration: {
    __component: Projectpage,
    projects: {},
  },
  installer: {
    __component: Installer,
  },
  legal: {
    __component: Textpage,
    __$text: 'Text-content-Impressum',
  },
  news: {
    __component: NewsOverview,
    ':NewsSlug': {
      __component: NewsDetail,
    },
    events: {
      __component: NewsDetail,
    },
    roadshow: {
      __component: NewsDetail,
    },
  },
  ordering: {
    __component: Textpage,
    __$text: 'ordering-textpage',
  },
  payment: {
    __component: Textpage,
    __$text: 'payment-textpage',
  },
  'privacy-policy': {
    __component: Textpage,
    __$text: 'Text-content-Datenschutz',
  },
  products: {
    __redirect: 'https://google.com/',
  },
  projects: {
    __component: Projectpage,
    ':projectname': {
      __component: ProjectDetail,
    },
  },
  'q-disc': {
    __component: QdiscPage,
  },
  register: {
    __component: Registerform,
  },
  returns: {
    __component: Textpage,
    __$text: 'returns-textpage',
  },
  service: {
    __component: Service,
    catalog: {
      __component: CatalogPage,
    },
    'catalogues-and-brochures': {
      __component: CatalogPage,
    },
    contact: {
      __component: Contact,
    },
    downloads: {
      __redirect: '/:locale/service/',
      'certificates-and-reports': {
        __component: Service,
      },
      ':dllcat': {
        __component: Downloads,
      },
    },
    'find-installer': {
      __redirect: '/service/find-an-installer/',
    },
    'find-an-installer': {
      __component: InstallerRequest,
    },
    ordering: {
      __component: Textpage,
      __$text: 'ordering-textpage',
    },
    payment: {
      __component: Textpage,
      __$text: 'payment-textpage',
    },
    prefix: {
      __component: Prefix,
    },
    returns: {
      __component: Textpage,
      __$text: 'returns-textpage',
    },
    roadshow: {
      __component: Roadshow,
    },
    shipping: {
      __component: Textpage,
      __$text: 'shipping-textpage',
    },
    trainings: {
      __component: TrainingsOverview,
      ':training': {
        __component: TrainingsDetail,
      },
    },
    videos: {
      __component: VideoOverview,
    },
  },
  systems: {
    __component: Systems,
    ':systems': {
      __component: Systemdetail,
    },
  },
  shipping: {
    __component: Textpage,
    __$text: 'shipping-textpage',
  },
  terms: {
    __component: Textpage,
    __$text: 'Text-content-Agbs',
  },
  search: {
    __component: SearchresultsPage,
    ':searchterm': {
      __component: SearchresultsPage,
    },
  },
};
