import { useEffect, useState } from 'react';
import { Utility } from '../utility/utility';
import { useLocation } from 'react-router-dom';

export const useLocale = (useCache = true) => {
  const [rendered, setRendered] = useState(false);
  const [locale, setLocale] = useState();
  const location = useLocation();

  useEffect(() => {
    if (locale) return;
    Utility.fetchLocale().then((locale) => {
      setLocale(locale);
      window.localStorage.setItem('fetched-locale', JSON.stringify({ locale, time: Date.now() }));
    });
  }, []);

  if (!rendered) {
    setRendered(true);
    const pathParts = location.pathname.split('/').filter((x) => x && x.trim() !== '');
    if (pathParts.length > 0 && Utility.isLocaleValid(pathParts[0])) {
      setLocale(pathParts[0]);
    } else if (useCache) {
      const cachedJson = window.localStorage.getItem('fetched-locale');
      if (cachedJson) {
        const cached = JSON.parse(cachedJson);
        cached.time = new Date(cached.time).getTime();
        if (Date.now() - cached.time < 1000 * 60 * 60 * 24) {
          setLocale(cached.locale);
        }
      }
    }
  }

  return locale;
};
