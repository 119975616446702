import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import NewsletterNew from './includes/NewsletterNew';
import SearchresultsMeili from './SearchresultsMeili';
import { Helmet } from 'react-helmet';

function SearchresultsPage() {
  return (
    <>
      <Helmet>
        <title>Search</title>
        <meta name="description" content="Q-railing Search"></meta>

        <link rel="alternate" hreflang="da-DK" href="https://q-railing.com/da-dk/search/" />
        <link rel="alternate" hreflang="de-AT" href="https://q-railing.com/de-at/search/" />
        <link rel="alternate" hreflang="de-CH" href="https://q-railing.com/de-ch/search/" />
        <link rel="alternate" hreflang="de-DE" href="https://q-railing.com/de-de/search/" />
        <link rel="alternate" hreflang="x-default" href="https://q-railing.com/en/search/" />
        <link rel="alternate" hreflang="en-AT" href="https://q-railing.com/en-at/search/" />
        <link rel="alternate" hreflang="en-CA" href="https://q-railing.com/en-ca/search/" />
        <link rel="alternate" hreflang="en-GB" href="https://q-railing.com/en-gb/search/" />
        <link rel="alternate" hreflang="en-IE" href="https://q-railing.com/en-ie/search/" />
        <link rel="alternate" hreflang="en-US" href="https://q-railing.com/en-us/search/" />
        <link rel="alternate" hreflang="es" href="https://q-railing.com/es/search/" />
        <link rel="alternate" hreflang="es-ES" href="https://q-railing.com/es-es/search/" />
        <link rel="alternate" hreflang="es-US" href="https://q-railing.com/es-us/search/" />
        <link rel="alternate" hreflang="fi-FI" href="https://q-railing.com/fi-fi/search/" />
        <link rel="alternate" hreflang="fr-BE" href="https://q-railing.com/fr-be/search/" />
        <link rel="alternate" hreflang="fr-CA" href="https://q-railing.com/fr-ca/search/" />
        <link rel="alternate" hreflang="fr-CH" href="https://q-railing.com/fr-ch/search/" />
        <link rel="alternate" hreflang="fr-FR" href="https://q-railing.com/fr-fr/search/" />
        <link rel="alternate" hreflang="fr-LU" href="https://q-railing.com/fr-lu/search/" />
        <link rel="alternate" hreflang="it-CH" href="https://q-railing.com/it-ch/search/" />
        <link rel="alternate" hreflang="it-IT" href="https://q-railing.com/it-it/search/" />
        <link rel="alternate" hreflang="nb-NO" href="https://q-railing.com/nb-no/search/" />
        <link rel="alternate" hreflang="nl-BE" href="https://q-railing.com/nl-be/search/" />
        <link rel="alternate" hreflang="nl-NL" href="https://q-railing.com/nl-nl/search/" />
        <link rel="alternate" hreflang="pl-PL" href="https://q-railing.com/pl-pl/search/" />
        <link rel="alternate" hreflang="pt-BR" href="https://q-railing.com/pt-br/search/" />
        <link rel="alternate" hreflang="pt-PT" href="https://q-railing.com/pt-pt/search/" />
        <link rel="alternate" hreflang="sv-SE" href="https://q-railing.com/sv-se/search/" />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <SearchresultsMeili searchpage={true}></SearchresultsMeili>
      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default SearchresultsPage;
