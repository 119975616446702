import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import './Block404.css';

function Block404() {
  let [systemres, setsystemres] = useState({ data: [] });
  let la;
  let arrla;
  let arrla1 = '';
  let styloo = {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginTop: '120px',
    marginBottom: '120px',
  };
  let styloo2 = {
    maxWidth: '1096px',
    width: '100%',
  };
  let styleHeader = {
    fontSize: '48px',
    fontWeight: '600',
    color: '#001020',
  };
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=404-page-text&populate=BannerImage',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
      });
  }, []);

  return (
    <>
      {systemres.data.length > 0 && (
        <div id="Block404-Banner-Image">
          {systemres.data[0].attributes.BannerImage && (
            <img src={systemres.data[0].attributes.BannerImage.data.attributes.url} />
          )}
          <div id="Block404-Banner-Text-wrap">
            <h2>{systemres.data[0].attributes.FreeTitle1}</h2>
            <p>{systemres.data[0].attributes.FreeTitle2}</p>
          </div>
        </div>
      )}
      {systemres.data.length > 0 && (
        <div className="Block404-wrap" style={styloo}>
          <div style={styloo2} className="Block404-inner">
            <h2 style={styleHeader}>{systemres.data[0].attributes.FreeTitle3}</h2> <br />
            <br />
            <div key={'404text'} dangerouslySetInnerHTML={{ __html: systemres.data[0].attributes.CustomText }} />
          </div>
        </div>
      )}
      {param.count == undefined && window.location.replace('/en-gb/')}
    </>
  );
}

export default Block404;
