import React from 'react';
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';

import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import './SwiperSlider.css';
import '../Modernslider.css';
import './SeoSlider.css';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';

function SeoSlider(props) {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let la;
  let arrla;
  let arrla1;
  let param = useParams();

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    if (!props.propmode) {
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=SeoSlider',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setsystemarr(json);
        });
    }

    if (props.propmode) {
      console.log(props.dataa);
      setsystemarr(props.dataa);
    }
  }, []);

  const breakpoints = {
    // When window width is >= 768px
    1024: {
      slidesPerView: 3,
    },
    768: {
      slidesPerView: 2,
    },
    // When window width is >= 576px
    576: {
      slidesPerView: 2,
    },
    // When window width is >= 320px
    320: {
      slidesPerView: 1,
    },
    100: {
      slidesPerView: 1,
    },
  };

  return (
    <>
      <div id="systemslider-jump"></div>
      {systemarr.data.length > 0 && !props.propmode && (
        <div className="SwiperSlider-wrap" id={props.color == 'grey' && 'GreySeoSlider'}>
          <div className="SwiperSlider-inner">
            <div className="Systems-slider-top-inner">
              <div className="Systems-slider-top-header">
                <h2>{systemarr.data.length > 0 && systemarr.data[0].attributes.FreeTitle1}</h2>
              </div>
              <div className="Systems-slider-top-text SwiperSlider-text">
                <div id="SeoSlider-inject-text">
                  {systemarr.data.length > 0 && (
                    <p dangerouslySetInnerHTML={{ __html: systemarr.data[0].attributes.FreeText1 }} />
                  )}
                </div>
                <div>
                  <button
                    className={systemarr.data[0].attributes.FreeData1.Slides.length < 4 ? 'hiddenSliderBtn' : ''}
                    aria-label="RelatedProjectsSlider-prev-btn"
                    id="Sliderprevbtn45"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-prev')) {
                        let indexbtnforward;

                        indexbtnforward = document.querySelectorAll('.swiper-button-prev')[props.slideramount];

                        indexbtnforward.click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>
                  <button
                    className={systemarr.data[0].attributes.FreeData1.Slides.length < 4 ? 'hiddenSliderBtn' : ''}
                    aria-label="RelatedProjectsSlider-back-btn"
                    id="Sliderprevbtn46"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-next')) {
                        let indexbtnback;
                        indexbtnback = document.querySelectorAll('.swiper-button-next')[props.slideramount];

                        if (props.gelaenderCat) {
                          indexbtnback = document.querySelectorAll('.swiper-button-next')[1];
                        }
                        indexbtnback.click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <Swiper
              id="swiperr"
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={24}
              slidesPerView={3}
              navigation
              pagination={false}
              scrollbar={{ draggable: true }}
              breakpoints={breakpoints}
            >
              {systemarr.data.length > 0 &&
                systemarr.data[0].attributes.FreeData1.Slides.map((item, index) => {
                  return (
                    <SwiperSlide key={'Systemcard' + index}>
                      <div className="product-card product-card2 product-card-system" id="product-card">
                        <Link hrefLang={param.count} to={item[1]}>
                          <img
                            className="systemimageslider"
                            src={item[3]}
                            alt={'SystemCard' + index}
                            loading="lazy"
                            width="300"
                            height={200}
                          />
                          <h3 className="systemsliderSystemspan">{item[0]}</h3>
                          <p className="systemsliderSystemtext">{item[2]}</p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
      {systemarr.data.length > 0 && props.propmode && (
        <div className="SwiperSlider-wrap">
          <div className="SwiperSlider-inner">
            <div className="Systems-slider-top-inner">
              <div className="Systems-slider-top-header">
                <h2>{systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData3.Slidertext[0]}</h2>
              </div>
              <div className="Systems-slider-top-text SwiperSlider-text">
                <div>
                  <p>{systemarr.data.length > 0 && systemarr.data[0].attributes.FreeData3.Slidertext[1]}</p>
                </div>
                <div>
                  <button
                    className={systemarr.data[0].attributes.FreeData3.Slides.length < 4 ? 'hiddenSliderBtn' : ''}
                    aria-label="RelatedProjectsSlider-prev-btn"
                    id="Sliderprevbtn45"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-prev')) {
                        let indexbtnforward;

                        indexbtnforward = document.querySelectorAll('.swiper-button-prev')[props.slideramount];

                        indexbtnforward.click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>
                  <button
                    className={systemarr.data[0].attributes.FreeData3.Slides.length < 4 ? 'hiddenSliderBtn' : ''}
                    aria-label="RelatedProjectsSlider-back-btn"
                    id="Sliderprevbtn46"
                    onClick={() => {
                      if (document.querySelector('.swiper-button-next')) {
                        let indexbtnback;
                        indexbtnback = document.querySelectorAll('.swiper-button-next')[props.slideramount];

                        if (props.gelaenderCat) {
                          indexbtnback = document.querySelectorAll('.swiper-button-next')[1];
                        }
                        indexbtnback.click();
                      }
                    }}
                  >
                    <svg width="14" height="12" viewBox="0 0 14 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        d="M13.0306 6.53074L8.53063 11.0307C8.38973 11.1716 8.19863 11.2508 7.99938 11.2508C7.80012 11.2508 7.60902 11.1716 7.46813 11.0307C7.32723 10.8898 7.24807 10.6987 7.24807 10.4995C7.24807 10.3002 7.32723 10.1091 7.46813 9.96824L10.6875 6.75011H1.5C1.30109 6.75011 1.11032 6.67109 0.96967 6.53044C0.829018 6.38979 0.75 6.19902 0.75 6.00011C0.75 5.8012 0.829018 5.61043 0.96967 5.46978C1.11032 5.32913 1.30109 5.25011 1.5 5.25011H10.6875L7.46937 2.03011C7.32848 1.88921 7.24932 1.69812 7.24932 1.49886C7.24932 1.2996 7.32848 1.10851 7.46937 0.967609C7.61027 0.826713 7.80137 0.747559 8.00062 0.747559C8.19988 0.747559 8.39098 0.826713 8.53187 0.967609L13.0319 5.46761C13.1018 5.53738 13.1573 5.62028 13.1951 5.71155C13.2329 5.80281 13.2523 5.90065 13.2522 5.99943C13.252 6.09822 13.2324 6.19601 13.1944 6.28718C13.1564 6.37836 13.1007 6.46113 13.0306 6.53074Z"
                        fill="#001020"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
            <Swiper
              id="swiperr"
              // install Swiper modules
              modules={[Navigation, Pagination, Scrollbar, A11y]}
              spaceBetween={24}
              slidesPerView={3}
              navigation
              pagination={false}
              scrollbar={{ draggable: true }}
              breakpoints={breakpoints}
            >
              {systemarr.data.length > 0 &&
                systemarr.data[0].attributes.FreeData3.Slides.map((item, index) => {
                  return (
                    <SwiperSlide key={'Systemcard' + index}>
                      <div className="product-card product-card2 product-card-system" id="product-card">
                        <Link hrefLang={param.count} to={item[1]}>
                          <img
                            className="systemimageslider"
                            src={item[3]}
                            alt={'SystemCard' + index}
                            loading="lazy"
                            width="300"
                            height={200}
                          />
                          <h3 className="systemsliderSystemspan">{item[0]}</h3>
                          <p className="systemsliderSystemtext">{item[2]}</p>
                        </Link>
                      </div>
                    </SwiperSlide>
                  );
                })}
            </Swiper>
          </div>
        </div>
      )}
    </>
  );
}

export default SeoSlider;
