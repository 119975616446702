import RecentProjects from './includes/RecentProjects';
import HeaderMainpage from './includes/HeaderMainpage';
import ConfiBlock from './includes/ConfiBlock';
import Howwework2 from './includes/Howwework2';
import { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import './Mainpage.css';
import './includes/Responsive.css';
import SwiperSlider from './includes/SwiperSlider';

import ServiceCards2 from './includes/ServiceCards2';

import Categoryslider from './includes/Categoryslider';

import NewsletterNew from './includes/NewsletterNew';
import Footernew from './includes/Footernew';

import { Helmet } from 'react-helmet';

import NeedHelp from './includes/NeedHelp';
import { Suspense } from 'react';
import SeoSlider from './includes/SeoSlider';

function Mainpage() {
  const param = useParams();

  let la;
  let arrla;
  let arrla1 = '';
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  const [showDiv, setShowDiv] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('RedirectBool') == 'true') {
      setShowDiv(true);
      setTimeout(() => {
        setShowDiv(false);
        localStorage.setItem('RedirectBool', 'false');
      }, 1200);
    }
  }, []);

  let [MetaData, setMetaData] = useState({ data: [] });

  useEffect(() => {
    const cachedData = localStorage.getItem(param.count + '-cachedDataMetaPages');
    if (cachedData && JSON.parse(cachedData).json.data.length > 0) {
      let now = new Date();
      setMetaData(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(param.count + '-cachedDataMetaPages');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=MetaDataPages',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setMetaData(json);
          localStorage.setItem(
            param.count + '-cachedDataMetaPages',
            JSON.stringify({ json, expiry: now.getTime() + 60 * 60 * 1000 }),
          );
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>{MetaData.data.length > 0 ? MetaData.data[0].attributes.FreeData1.Mainpage[0] : 'Q-railing'}</title>
        <meta
          name="description"
          content={
            MetaData.data.length > 0
              ? MetaData.data[0].attributes.FreeData1.Mainpage[1]
              : 'the premium brand in railing systems'
          }
        ></meta>
        <link rel="alternate" hreflang="da-DK" href="https://q-railing.com/da-dk/" />
        <link rel="alternate" hreflang="de-AT" href="https://q-railing.com/de-at/" />
        <link rel="alternate" hreflang="de-CH" href="https://q-railing.com/de-ch/" />
        <link rel="alternate" hreflang="de-DE" href="https://q-railing.com/de-de/" />
        <link rel="alternate" hreflang="x-default" href="https://q-railing.com/en/" />
        <link rel="alternate" hreflang="en-CA" href="https://q-railing.com/en-ca/" />
        <link rel="alternate" hreflang="en-AT" href="https://q-railing.com/en-at/" />
        <link rel="alternate" hreflang="en-GB" href="https://q-railing.com/en-gb/" />
        <link rel="alternate" hreflang="en-IE" href="https://q-railing.com/en-ie/" />
        <link rel="alternate" hreflang="en-US" href="https://q-railing.com/en-us/" />
        <link rel="alternate" hreflang="es" href="https://q-railing.com/es/" />
        <link rel="alternate" hreflang="es-ES" href="https://q-railing.com/es-es/" />
        <link rel="alternate" hreflang="es-US" href="https://q-railing.com/es-us/" />
        <link rel="alternate" hreflang="fi-FI" href="https://q-railing.com/fi-fi/" />
        <link rel="alternate" hreflang="fr-BE" href="https://q-railing.com/fr-be/" />
        <link rel="alternate" hreflang="fr-CA" href="https://q-railing.com/fr-ca/" />
        <link rel="alternate" hreflang="fr-CH" href="https://q-railing.com/fr-ch/" />
        <link rel="alternate" hreflang="fr-FR" href="https://q-railing.com/fr-fr/" />
        <link rel="alternate" hreflang="fr-LU" href="https://q-railing.com/fr-lu/" />
        <link rel="alternate" hreflang="it-CH" href="https://q-railing.com/it-ch/" />
        <link rel="alternate" hreflang="it-IT" href="https://q-railing.com/it-it/" />
        <link rel="alternate" hreflang="nb-NO" href="https://q-railing.com/nb-no/" />
        <link rel="alternate" hreflang="nl-BE" href="https://q-railing.com/nl-be/" />
        <link rel="alternate" hreflang="nl-NL" href="https://q-railing.com/nl-nl/" />
        <link rel="alternate" hreflang="pl-PL" href="https://q-railing.com/pl-pl/" />
        <link rel="alternate" hreflang="pt-BR" href="https://q-railing.com/pt-br/" />
        <link rel="alternate" hreflang="pt-PT" href="https://q-railing.com/pt-pt/" />
        <link rel="alternate" hreflang="sv-SE" href="https://q-railing.com/sv-se/" />
      </Helmet>
      {showDiv && (
        <div className="Q-railing-Intro-wrap">
          <div className="Q-railing-Intro-inner">
            <div id="Q-railing-loading">
              <img
                id="Q-railing-logo"
                className="jump-and-scale"
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q_logo_white.webp"
                width="72"
                height={'72'}
                alt="Q-railing logo"
              />
            </div>
          </div>
        </div>
      )}
      <HeaderMainpage></HeaderMainpage>
      <Suspense>
        <ConfiBlock color="grey"></ConfiBlock>
      </Suspense>
      <Suspense>
        <Howwework2 color="white"></Howwework2>
      </Suspense>
      <SwiperSlider SystemTopCat="true" color="grey"></SwiperSlider>
      <ServiceCards2 color="white"></ServiceCards2>
      <RecentProjects color="grey"></RecentProjects>
      <Categoryslider slideramount={1} color="white"></Categoryslider>
      <SeoSlider slideramount={2} color="grey"></SeoSlider>
      <NeedHelp color="white"></NeedHelp>

      <NewsletterNew color="grey" />

      <Footernew></Footernew>
    </>
  );
}

export default Mainpage;
