import './Mainpage.css';

import './Systems.css';
import Footernew from './includes/Footernew';
import Systemgrid from './includes/Systemgrid';
import NewsletterNew from './includes/NewsletterNew';

import HeaderSlim from './includes/HeaderSlim';
import SystemHeadline from './includes/SystemHeadline';
import { useLocation, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import LinkData from './includes/Links.json';

function Systems() {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  let arrla;
  let arrla1;
  let la;
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let numberss = 8;
  let savearr = [];
  LinkData.data.Locales.forEach((item, index) => {
    let arry = [];
    if (item) {
      let splitlocale = [];
      if (item != 'es') {
        splitlocale = item.split('-');
      }

      arry = splitlocale[0] + '_arr';
      if (item == 'es') {
        arry = 'es_arr';
      }
      if (item == 'en-us') {
        arry = 'en_us_arr';
      }
      if (item == 'en-ca') {
        arry = 'en_us_arr';
      }
      if (item == 'x-default') {
        arry = 'en_arr';
      }
      let saveitem = item;
      if (item == 'x-default') {
        saveitem = 'x-default';
        item = 'en';
      }
      let actlink = LinkData.data[arry];
      try {
        savearr[index] = ['https://q-railing.com/' + item + actlink[numberss], saveitem];
      } catch (error) {}
    } else {
    }
  });

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  let [MetaData, setMetaData] = useState({ data: [] });

  useEffect(() => {
    const cachedData = localStorage.getItem(param.count + '-cachedDataMetaPages');
    if (cachedData && JSON.parse(cachedData).json.data.length > 0) {
      let now = new Date();
      setMetaData(JSON.parse(cachedData).json);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(param.count + '-cachedDataMetaPages');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=MetaDataPages',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setMetaData(json);
          localStorage.setItem(
            param.count + '-cachedDataMetaPages',
            JSON.stringify({ json, expiry: now.getTime() + 24 * 60 * 60 * 1000 }),
          );
        });
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>
          {MetaData.data.length > 0 ? MetaData.data[0].attributes.FreeData1.SystemOverview[0] : 'Q-railing'}
        </title>
        <meta
          name="description"
          content={
            MetaData.data.length > 0
              ? MetaData.data[0].attributes.FreeData1.SystemOverview[1]
              : 'the premium brand in railing systems'
          }
        ></meta>
        {savearr.length > 0 &&
          savearr.map(
            (item, index) => item && <link key={index} rel={'alternate'} hrefLang={'' + item[1]} href={'' + item[0]} />,
          )}
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <SystemHeadline></SystemHeadline>
      <Systemgrid></Systemgrid>

      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default Systems;
