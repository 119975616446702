import React, { useRef } from 'react';
import { useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import '../Searchresults.css';
import './SearchDropdown.css';
import { useEffect } from 'react';
import Links from './Links.json';

function SearchDropdownMeili(props) {
  let para = useParams();
  let la;
  let arrla;
  let arrla1 = '';
  const Localemap = {
    'da-dk': 'da_arr',
    'de-at': 'de_arr',
    'de-ch': 'de_arr',
    'de-de': 'de_arr',
    'en-at': 'en_arr',
    'x-default': 'en_arr',
    'en-ca': 'en_arr',
    'en-gb': 'en_arr',
    'en-ie': 'en_arr',
    'en-us': 'en_us_arr',
    es: 'es_arr',
    'es-es': 'es_arr',
    'es-us': 'es_arr',
    'fi-fi': 'fi_arr',
    'fr-be': 'fr_arr',
    'fr-ca': 'fr_arr',
    'fr-fr': 'fr_arr',
    'fr-ch': 'fr_arr',
    'fr-lu': 'fr_arr',
    'it-ch': 'it_arr',
    'it-it': 'it_arr',
    'nb-no': 'nb_arr',
    en: 'en_arr',
    'nl-nl': 'nl_arr',
    'nl-be': 'nl_arr',
    'pl-pl': 'pl_arr',
    'pt-br': 'pt_arr',
    'pt-pt': 'pt_arr',
    'sv-se': 'sv_arr',
  };

  let syslink = Links.data[Localemap[para.count]][8];

  const translations = {
    'da-dk': 'Skriv her...',
    'de-at': 'Hier tippen...',
    'de-ch': 'Hier tippen...',
    'de-de': 'Hier tippen...',
    'x-default': 'Type here...',
    'en-ca': 'Type here...',
    'en-gb': 'Type here...',
    'en-ie': 'Type here...',
    'en-us': 'Type here...',
    es: 'Escribe aquí...',
    en: 'type here...',
    'es-es': 'Escribe aquí...',
    'es-us': 'Escribe aquí...',
    'fi-fi': 'Kirjoita tähän...',
    'fr-be': 'Tapez ici...',
    'fr-ca': 'Tapez ici...',
    'fr-ch': 'Tapez ici...',
    'fr-lu': 'Tapez ici...',
    'it-ch': 'Digita qui...',
    'it-it': 'Digita qui...',
    'nb-no': 'Skriv her...',
    'nl-nl': 'Typ hier...',
    'nl-be': 'Typ hier...',
    'pl-pl': 'Wpisz tutaj...',
    'pt-br': 'Digite aqui...',
    'pt-pt': 'Digite aqui...',
    'sv-se': 'Skriv här...',
  };
  const locale = para.count;
  const translatedTextTypeHere = translations[locale] || translations['x-default'];
  let [searchwords, setsearchwords] = useState('');
  let [trashrequest, settrashrequest] = useState(false);

  let [searchwordlock] = useState('');

  let [listtoggle, setlisttoggle] = useState(false);
  let searchfieldref = useRef(null);
  let searchsystemlink = useRef(null);
  let searchbarref = useRef(null);
  window.onscroll = function () {
    myscrollFunction();
  };
  //var navbar = document.querySelector(".header-top-wrap");

  function myscrollFunction() {
    let navbar = searchbarref.current;
    let searchfield = searchfieldref.current;
    let systemlink = searchsystemlink.current;
    var sticky = 50;
    if (window.pageYOffset >= sticky) {
      navbar.classList.add('sticky');

      searchfield.classList.add('stickysearch');
      systemlink.classList.add('stickysearchlink');
    } else {
      if (navbar.classList.contains('sticky')) {
        navbar.classList.remove('sticky');
        searchfield.classList.remove('stickysearch');
        systemlink.classList.remove('stickysearchlink');
      }
    }
  }

  const apiKey2 = '3dc89833d80b98094d113cc0e4db06a4fcfd3011312fdae608b0e02a3e374cbc';
  if (para.count) {
    la = para.count;
    if (la.match('-')) {
      arrla = la.split('-');
      if (arrla[1]) {
        arrla[1] = arrla[1].toUpperCase();
        arrla1 = arrla[0] + '-' + arrla[1];
      }
    } else {
      arrla1 = para.count;
    }
  } else {
    la = 'en';
  }

  let [dopamount, setdopamount] = useState(0);
  let [sitesamount, setsitesamount] = useState(0);

  let [finalprods, setfinalprods] = useState([]);
  let [finalsites, setfinalsites] = useState([]);
  let [finaldops, setfinaldops] = useState([]);

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  let [pagetranslations, setpagetranslations] = useState({ data: [] });
  let shadowref = useRef(null);
  let internalref = useRef(null);
  useEffect(() => {
    if (listtoggle) {
      shadowref.current.style = 'display:block';
      document.body.style = 'overflow-y:hidden';
      if (internalref.current) {
        internalref.current.style = 'min-height:517px';
      }
    } else {
      shadowref.current.style = 'display:none';
      document.body.style = 'overflow-y:unset';

      if (internalref.current) {
        internalref.current.style = 'min-height:unset';
      }
    }
  }, [listtoggle]);

  let searchinput = useRef(null);
  useEffect(() => {
    searchinput.current.focus();
  }, []);

  useEffect(() => {
    if (para.searchterm) {
      let searchwordinput = para.searchterm;
      searchwordinput = searchwordinput.replace('%20', ' ');
      document.getElementById('searchinput').value = searchwordinput;
    }

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=GatheringWordTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslations(json);
      });
  }, []);

  useEffect(() => {
    if (document.getElementById('searchinput')) {
      document.getElementById('searchinput').addEventListener('keydown', function (event) {
        // Check if the pressed key is Enter (key code 13)

        if (event.keyCode === 13) {
          // Prevent the default behavior of the Enter key (form submission)
          event.preventDefault();
          // Trigger the button click
          document.getElementById('searchbuttonclick').click();
        }
      });
    }
  }, []);

  useEffect(() => {
    // Set a timer for 100ms
    const timer = setTimeout(() => {
      if (searchwords) {
        // Call your search functions here
        searchproducts(searchwords);
        searchdocs(searchwords);
        searchsites(searchwords);
      }
    }, 500);

    // Clear the timer if the user continues typing within the delay
    return () => clearTimeout(timer);
  }, [searchwords]);

  async function searchproducts() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-products/search';

    const isArticleNumber = /^\d{6}/.test(searchwords);
    let searchParams = { q: searchwords, offset: 0, limit: 3 };

    if (isArticleNumber) {
      console.log('isArticleNumber', isArticleNumber);
      searchParams.filter = `ArticleNumber = ${searchwords}`;
    }
    console.log(searchParams);
    try {
      let response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey2}`,
        },
        body: JSON.stringify(searchParams),
      });

      let data = await response.json();

      if (data && data.hits.length === 0 && isArticleNumber) {
        delete searchParams.filters;
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey2}`,
          },
          body: JSON.stringify({ q: searchwords, offset: 0, limit: 30 }),
        });

        data = await response.json();
      }
      console.log(data);
      setfinalprods(data.hits);
      returnproducts(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  async function searchdocs() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-downloads/search';

    const isArticleNumber2 = /^\d{6}/.test(searchwords);
    let searchParams2 = { q: searchwords, offset: 0, limit: 3 };

    if (isArticleNumber2) {
      console.log('isArticleNumberHIER', isArticleNumber2);
      searchParams2.filter = `SearchCategory1 = ${searchwords}`;
    }
    console.log('qweqwed');

    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey2}`,
        },
        body: JSON.stringify(searchParams2),
      });

      if (!response.ok) {
        if (response.status === 403) {
          setlisttoggle(false);
          settrashrequest(true);
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      if (data && data.hits.length === 0 && isArticleNumber2) {
        delete searchParams2.filters;
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${apiKey2}`,
          },
          body: JSON.stringify({ q: searchwords, offset: 0, limit: 3 }),
        });

        data = await response.json();
      }
      setdopamount(data.estimatedTotalHits);
      setfinaldops(data.hits);
      returndocs(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }
  async function searchsites() {
    const url = 'https://qrailingsearch.space/indexes/' + para.count + '-system/search';
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${apiKey2}`,
        },
        body: JSON.stringify({ q: searchwords, offset: 0, limit: 21 }),
      });

      const data = await response.json();
      if (!response.ok) {
        if (response.status === 403) {
          // Handle 403 error here
          setlisttoggle(false);
          settrashrequest(true); // Assuming setListToggle is your state setter function
          // setAnotherBool(false); // Uncomment this if you have another state to set
        }
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      setsitesamount(data.estimatedTotalHits);
      setfinalsites(data.hits);
      returnsystems(data.hits);
    } catch (error) {
      console.error('Error:', error);
    }
  }

  function returnproducts() {
    if (finalprods.length > 0) {
      if (!listtoggle) {
        setlisttoggle(true);
      }

      return (
        <>
          {finalprods &&
            finalprods.map((item, index) => {
              console.log(item);
              if (index < 3)
                return (
                  <>
                    <a className="product-cardy-link" href={item.Shoplink} target="_blank">
                      <div className="product-cardy">
                        <img
                          width="100"
                          height={100}
                          alt={'product-image-' + item.ArticleNumber}
                          src={item.CardImageUrl}
                        />
                        <div className="SearchDropdown-Products-card-inner">
                          <h3>{item.Name}</h3>
                          <span>
                            {item.ArticleNumber}{' '}
                            <img
                              width={13}
                              height={13}
                              id="product-card-link-icon"
                              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/Link-Arrow.svg"
                            />
                          </span>
                        </div>
                      </div>
                    </a>
                  </>
                );
            })}
        </>
      );
    }
  }

  function returndocs() {
    if (finaldops && !trashrequest) {
      if (!listtoggle) {
        setlisttoggle(true);
      }
      return (
        <>
          <div>
            {finaldops &&
              finaldops.map((item, index) => {
                console.log(item);
                if (index < 2) {
                  return (
                    <>
                      {!item.file && (
                        <a
                          target="_blank"
                          hrefLang={para.count}
                          href={
                            item.Category != 'Product Performance Sheet'
                              ? 'https://strapiasi3pstore.blob.core.windows.net/downloads-files/' + item.FileName
                              : 'https://strapiasi3pstore.blob.core.windows.net/propers/' + item.FileName
                          }
                        >
                          <div className="docs-card">
                            <span>{item.DisplayName}</span>
                            <svg
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M13.25 8.5V12C13.25 12.3315 13.1183 12.6495 12.8839 12.8839C12.6495 13.1183 12.3315 13.25 12 13.25H2C1.66848 13.25 1.35054 13.1183 1.11612 12.8839C0.881696 12.6495 0.75 12.3315 0.75 12V8.5C0.75 8.30109 0.829018 8.11032 0.96967 7.96967C1.11032 7.82902 1.30109 7.75 1.5 7.75C1.69891 7.75 1.88968 7.82902 2.03033 7.96967C2.17098 8.11032 2.25 8.30109 2.25 8.5V11.75H11.75V8.5C11.75 8.30109 11.829 8.11032 11.9697 7.96967C12.1103 7.82902 12.3011 7.75 12.5 7.75C12.6989 7.75 12.8897 7.82902 13.0303 7.96967C13.171 8.11032 13.25 8.30109 13.25 8.5ZM6.46938 9.03063C6.53905 9.10054 6.62185 9.15602 6.71301 9.19388C6.80417 9.23173 6.90191 9.25122 7.00062 9.25122C7.09934 9.25122 7.19707 9.23173 7.28824 9.19388C7.3794 9.15602 7.4622 9.10054 7.53187 9.03063L10.0319 6.53062C10.1728 6.38973 10.2519 6.19863 10.2519 5.99937C10.2519 5.80012 10.1728 5.60902 10.0319 5.46812C9.89098 5.32723 9.69988 5.24807 9.50062 5.24807C9.30137 5.24807 9.11027 5.32723 8.96937 5.46812L7.75 6.6875V1.5C7.75 1.30109 7.67098 1.11032 7.53033 0.96967C7.38968 0.829018 7.19891 0.75 7 0.75C6.80109 0.75 6.61032 0.829018 6.46967 0.96967C6.32902 1.11032 6.25 1.30109 6.25 1.5V6.6875L5.03062 5.46938C4.88973 5.32848 4.69863 5.24932 4.49937 5.24932C4.30012 5.24932 4.10902 5.32848 3.96812 5.46938C3.82723 5.61027 3.74807 5.80137 3.74807 6.00063C3.74807 6.19988 3.82723 6.39098 3.96812 6.53188L6.46938 9.03063Z"
                                fill="#001020"
                              />
                            </svg>
                          </div>
                        </a>
                      )}
                      {item.file && (
                        <div className="docs-card">
                          <img width="64" alt={'image - ' + item.Titel} src={item.type} />
                          <span>{item.attributes.Titel}</span>
                          <div className="read-pdf-box">
                            <a target="_blank" hrefLang={para.count} href={item.file}>
                              Read
                            </a>
                            <a target="_blank" download href={item.Link}>
                              Download
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                }
              })}
          </div>
        </>
      );
    }
  }

  function returnsystems() {
    if (finalsites && !trashrequest) {
      if (!listtoggle) {
        setlisttoggle(true);
      }

      return (
        <>
          {finalsites.map((item, index) => {
            if (index < 1) {
              return (
                <>
                  <Link
                    className="searchresults-link-systems"
                    hrefLang={para.count}
                    to={'/' + para.count + syslink + item.Slug + '/'}
                  >
                    <img src={item.CardImageUrl} width={312} height={208} />
                    <div className="SearchDropdown-Systems-card-inner">
                      <h4>{item.Title}</h4>
                      <p>{item.MetaTitle}</p>
                    </div>
                  </Link>
                </>
              );
            }
          })}
        </>
      );
    }
  }

  return (
    <>
      <div className="Searchresultspage-wrap" ref={searchbarref} id="SearchDropdown-wrap2">
        <div
          className="Searchresults-container"
          id={props.searchpage ? 'Searchpage-inner-id' : 'Searchresult-inner-id'}
        >
          <div className="Searchresults-search">
            <Link id="searchLogo" hrefLang={para.count} to={'/' + para.count + '/'}>
              <img
                src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q-logo.png"
                width="72"
                height={'72'}
                alt="Q-railing logo"
              />
            </Link>
            {!props.searchpage && (
              <div className="Searchresults-searchbar-inner">
                <img
                  alt="Link icon Search-responsive"
                  src={'https://q-railing.s3.eu-central-1.amazonaws.com/icons/lupe_black.png'}
                  width="20"
                  height="20"
                />
                <input
                  ref={searchinput}
                  onKeyDown={(event) => {
                    if (event.keyCode === 13) {
                      event.preventDefault();
                      if (document.getElementById('searchbuttonclick')) {
                        document.getElementById('searchbuttonclick').click();
                      }
                    }
                  }}
                  onChange={(e) => {
                    setsearchwords(e.target.value);
                    searchwordlock = e.target.value;
                  }}
                  value={searchwords}
                  type="text"
                  id="searchinput"
                  placeholder={translatedTextTypeHere}
                />
              </div>
            )}

            <div className="Searchresults-searchbar-buttons">
              {searchwordlock && (
                <button
                  id="searchbuttonclick"
                  onClick={() => window.location.replace('/' + para.count + '/search/' + searchwords)}
                >
                  Search
                </button>
              )}
              <button
                id="closesearchbar-btn"
                onClick={() => {
                  setlisttoggle(false);
                  document.body.style = 'overflow-y:unset ';
                  props.searchtoggle(false);
                }}
              >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M15.281 14.2193C15.3507 14.289 15.406 14.3717 15.4437 14.4628C15.4814 14.5538 15.5008 14.6514 15.5008 14.7499C15.5008 14.8485 15.4814 14.9461 15.4437 15.0371C15.406 15.1281 15.3507 15.2109 15.281 15.2806C15.2114 15.3502 15.1286 15.4055 15.0376 15.4432C14.9465 15.4809 14.849 15.5003 14.7504 15.5003C14.6519 15.5003 14.5543 15.4809 14.4632 15.4432C14.3722 15.4055 14.2895 15.3502 14.2198 15.2806L8.00042 9.06024L1.78104 15.2806C1.64031 15.4213 1.44944 15.5003 1.25042 15.5003C1.05139 15.5003 0.860523 15.4213 0.719792 15.2806C0.579062 15.1398 0.5 14.949 0.5 14.7499C0.5 14.5509 0.579062 14.36 0.719792 14.2193L6.9401 7.99993L0.719792 1.78055C0.579062 1.63982 0.5 1.44895 0.5 1.24993C0.5 1.05091 0.579062 0.860034 0.719792 0.719304C0.860523 0.578573 1.05139 0.499512 1.25042 0.499512C1.44944 0.499512 1.64031 0.578573 1.78104 0.719304L8.00042 6.93962L14.2198 0.719304C14.3605 0.578573 14.5514 0.499512 14.7504 0.499512C14.9494 0.499512 15.1403 0.578573 15.281 0.719304C15.4218 0.860034 15.5008 1.05091 15.5008 1.24993C15.5008 1.44895 15.4218 1.63982 15.281 1.78055L9.06073 7.99993L15.281 14.2193Z"
                    fill="black"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="Searchresultspage-wrap" id="SearchDropdown-wrap">
        <div
          className="Searchresults-container"
          id={props.searchpage ? 'Searchpage-inner-id' : 'Searchresult-inner-id'}
        >
          {listtoggle && (finaldops.length > 0 || finalprods.length > 0 || finalsites.length > 0) && (
            <div ref={internalref} id="SearchDropdown-results-ok" className="Searchresults-results SearchDropdown-wrap">
              <div className="SearchDropdown-left">
                {finalprods.length > 0 && (
                  <h2>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[3]
                      : 'Products'}{' '}
                  </h2>
                )}
                <div className="Searchresults-Products-Wrap">{returnproducts()}</div>
                <a
                  id="SearchDropdown-seeSearchpage"
                  href={
                    searchwords != 'dwg' && searchwords != 'dwgs'
                      ? '/' + para.count + '/search/' + searchwords
                      : '/' + para.count + '/service/downloads/cad/'
                  }
                >
                  {pagetranslations.data.length > 0
                    ? pagetranslations.data[0].attributes.FreeData1.Searchpage[7]
                    : 'View all Results'}
                </a>
              </div>

              <div className="SearchDropdown-right">
                <div className="Searchresults-Sites">
                  {finalsites && (
                    <h2 className={sitesamount == 0 && 'hidden'}>
                      {pagetranslations.data.length > 0
                        ? pagetranslations.data[0].attributes.FreeData1.Searchpage[4]
                        : 'Systems'}{' '}
                    </h2>
                  )}
                  <div className="Searchresults-Systems-Grid">{returnsystems()}</div>
                </div>

                <div className="Searchresults-Downloads">
                  <h2 className={dopamount == 0 && 'hidden'}>
                    {pagetranslations.data.length > 0
                      ? pagetranslations.data[0].attributes.FreeData1.Searchpage[5]
                      : 'Service'}{' '}
                  </h2>
                  {returndocs()}
                </div>

                <a
                  id="SearchDropdown-seeSearchpage-respbtn"
                  className="SearchDropdown-resp-seeallbutton"
                  href={
                    searchwords != 'dwg' && searchwords != 'dwgs'
                      ? '/' + para.count + '/search/' + searchwords
                      : '/' + para.count + '/service/downloads/cad/'
                  }
                >
                  {pagetranslations.data.length > 0
                    ? pagetranslations.data[0].attributes.FreeData1.Searchpage[7]
                    : 'View all Results'}
                </a>
              </div>
            </div>
          )}

          {listtoggle && finaldops.length == 0 && finalprods.length == 0 && finalsites.length == 0 && (
            <>
              <div className="SearchDropdown-Noresults">
                <h3>
                  {pagetranslations.data.length > 0
                    ? pagetranslations.data[0].attributes.FreeData1.Searchpage[8]
                    : 'No results found for'}
                  "{searchwords}"
                </h3>
              </div>
            </>
          )}
        </div>
      </div>
      {listtoggle && (
        <div className="SearchDropdown-Systembtn-wrap" ref={searchsystemlink}>
          <div className="SearchDropdown-Systembtn-inner">
            <Link hrefLang={para.count} className="" to={'/' + para.count + '/systems/'}>
              {pagetranslations.data.length > 0
                ? pagetranslations.data[0].attributes.FreeData1.Searchpage[9]
                : 'All Systems'}
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="32" height="32" rx="16" fill="#F2F3F4" />
                <path
                  d="M22.0306 16.5307L17.5306 21.0307C17.3897 21.1716 17.1986 21.2508 16.9994 21.2508C16.8001 21.2508 16.609 21.1716 16.4681 21.0307C16.3272 20.8898 16.2481 20.6987 16.2481 20.4995C16.2481 20.3002 16.3272 20.1091 16.4681 19.9682L19.6875 16.7501H10.5C10.3011 16.7501 10.1103 16.6711 9.96967 16.5304C9.82902 16.3898 9.75 16.199 9.75 16.0001C9.75 15.8012 9.82902 15.6104 9.96967 15.4698C10.1103 15.3291 10.3011 15.2501 10.5 15.2501H19.6875L16.4694 12.0301C16.3285 11.8892 16.2493 11.6981 16.2493 11.4989C16.2493 11.2996 16.3285 11.1085 16.4694 10.9676C16.6103 10.8267 16.8014 10.7476 17.0006 10.7476C17.1999 10.7476 17.391 10.8267 17.5319 10.9676L22.0319 15.4676C22.1018 15.5374 22.1573 15.6203 22.1951 15.7115C22.2329 15.8028 22.2523 15.9006 22.2522 15.9994C22.252 16.0982 22.2324 16.196 22.1944 16.2872C22.1564 16.3784 22.1007 16.4611 22.0306 16.5307Z"
                  fill="#001020"
                />
              </svg>
            </Link>
          </div>
        </div>
      )}

      <div ref={shadowref} id="Q-railing-menu-shadow-2"></div>
    </>
  );
}

export default SearchDropdownMeili;
