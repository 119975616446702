import React, { useEffect, useState } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './News.css';
import HeaderSlim from './includes/HeaderSlim';
import Footernew from './includes/Footernew';
import NewsletterNew from './includes/NewsletterNew';
import { Helmet } from 'react-helmet';

function NewsOverview() {
  let [systemarr, setsystemarr] = useState({ data: [] });
  let [page, setpage] = useState(1);
  let [pagetranslations, setpagetranslation] = useState({ data: [] });
  let la;
  let arrla;
  let para = useParams();
  let param = useParams();
  let arrla1 = '';

  const translations = {
    'da-dk': [
      'De seneste opdateringer fra Q-railing: Virksomhedsnyheder og udviklinger',
      'Hold dig informeret om de seneste nyheder fra Q-railing, og gå ikke glip af trends i gelænderbranchen.',
    ],
    'de-at': [
      'Q-railing informiert: Neuigkeiten aus der Geländer-Industrie',
      'Bleiben Sie stets informiert über Neuigkeiten von Q-railing und verpassen Sie keine Trends der Geländer-Branche',
    ],
    'de-ch': [
      'Q-railing informiert: Neuigkeiten aus der Geländer-Industrie',
      'Bleiben Sie stets informiert über Neuigkeiten von Q-railing und verpassen Sie keine Trends der Geländer-Branche',
    ],
    'de-de': [
      'Q-railing informiert: Neuigkeiten aus der Geländer-Industrie',
      'Bleiben Sie stets informiert über Neuigkeiten von Q-railing und verpassen Sie keine Trends der Geländer-Branche',
    ],
    'x-default': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
    'en-ca': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
    'en-gb': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
    'en-ie': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
    'en-us': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
    es: [
      'Las últimas actualizaciones de Q-railing: Noticias y desarrollos de la empresa',
      'Manténgase informado sobre las últimas novedades de Q-railing y no se pierda ninguna tendencia en el sector de las barandillas',
    ],
    'es-es': [
      'Las últimas actualizaciones de Q-railing: Noticias y desarrollos de la empresa',
      'Manténgase informado sobre las últimas novedades de Q-railing y no se pierda ninguna tendencia en el sector de las barandillas',
    ],
    'es-us': [
      'Las últimas actualizaciones de Q-railing: Noticias y desarrollos de la empresa',
      'Manténgase informado sobre las últimas novedades de Q-railing y no se pierda ninguna tendencia en el sector de las barandillas',
    ],
    'fi-fi': [
      'Uusimmat päivitykset Q-railingilta: Yritysuutiset ja kehitykset',
      'Pysy ajan tasalla Q-railingin uusimmista uutisista etkä jää paitsi kaidealan trendeistä.',
    ],
    'fr-be': [
      "Les dernières mises à jour de Q-railing : Actualités et développements de l'entreprise",
      'Restez informé des nouveautés de Q-railing et ne manquez pas les tendances du secteur des garde-corps.',
    ],
    'fr-ca': [
      "Les dernières mises à jour de Q-railing : Actualités et développements de l'entreprise",
      'Restez informé des nouveautés de Q-railing et ne manquez pas les tendances du secteur des garde-corps.',
    ],
    'fr-ch': [
      "Les dernières mises à jour de Q-railing : Actualités et développements de l'entreprise",
      'Restez informé des nouveautés de Q-railing et ne manquez pas les tendances du secteur des garde-corps.',
    ],
    'fr-fr': [
      "Les dernières mises à jour de Q-railing : Actualités et développements de l'entreprise",
      'Restez informé des nouveautés de Q-railing et ne manquez pas les tendances du secteur des garde-corps.',
    ],
    'fr-lu': [
      "Les dernières mises à jour de Q-railing : Actualités et développements de l'entreprise",
      'Restez informé des nouveautés de Q-railing et ne manquez pas les tendances du secteur des garde-corps.',
    ],
    'it-ch': [
      'Le ultime novità da Q-railing: Notizie aziendali e sviluppi',
      'Rimanete informati sulle ultime novità di Q-railing e non perdete le tendenze del settore dei parapetti',
    ],
    'it-it': [
      'Le ultime novità da Q-railing: Notizie aziendali e sviluppi',
      'Rimanete informati sulle ultime novità di Q-railing e non perdete le tendenze del settore dei parapetti',
    ],
    'nb-no': [
      'De siste oppdateringene fra Q-railing: Bedriftsnyheter og utviklinger',
      'Hold deg informert om de siste nyhetene fra Q-railing og gå ikke glipp av noen trender i rekkverksbransjen',
    ],
    'nl-nl': [
      'De laatste updates van Q-railing: Bedrijfsnieuws & ontwikkelingen',
      'Blijf op de hoogte van het laatste nieuws van Q-railing en mis geen enkele trend in de railingindustrie',
    ],
    'nl-be': [
      'De laatste updates van Q-railing: Bedrijfsnieuws & ontwikkelingen',
      'Blijf op de hoogte van het laatste nieuws van Q-railing en mis geen enkele trend in de railingindustrie',
    ],
    'pl-pl': [
      'Najnowsze aktualizacje od Q-railing: Wiadomości i rozwój firmy',
      'Bądź na bieżąco z najnowszymi wiadomościami od Q-railing i nie przegap żadnych trendów w branży balustrad',
    ],
    'pt-br': [
      'As últimas atualizações da Q-railing: Notícias da empresa e desenvolvimentos',
      'Mantenha-se informado sobre as últimas notícias da Q-railing e não perca as tendências do sector dos carris',
    ],
    'pt-pt': [
      'As últimas atualizações da Q-railing: Notícias da empresa e desenvolvimentos',
      'Mantenha-se informado sobre as últimas notícias da Q-railing e não perca as tendências do sector dos carris',
    ],
    'sv-se': [
      'De senaste uppdateringarna från Q-railing: Företagsnyheter och utvecklingar',
      'Håll dig informerad om de senaste nyheterna från Q-railing och missa inte några trender inom räckesindustrin',
    ],
    'en-at': [
      'The latest updates from Q-railing: Company news & developments',
      "Stay informed about the latest news from Q-railing and don't miss any trends in the railing industry",
    ],
  };
  const locale = para.count;
  const translatedTextTypeHere = translations[locale] || translations['x-default'];

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, page]);

  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
        arrla1 +
        '&filters[Slug][$eq]=GatheringWordTranslations',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setpagetranslation(json);
      });

    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/news?locale=' +
        arrla1 +
        '&populate=CardImage3x2&pagination[pageSize]=3&sort[0]=Date:desc',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
      })
      .then(() => {
        reloadgrid();
        loadpage();
      });
  }, []);

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/news?locale=' +
        arrla1 +
        '&populate=CardImage3x2&pagination[page]=' +
        page +
        '&pagination[pageSize]=3&sort[0]=Date:desc',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemarr(json);
      })
      .then(() => {
        reloadgrid();
        loadpage();
      });
  }, [page]);

  function loadpage() {
    if (systemarr.data.length > 0) {
      if (systemarr.meta.pagination.pageCount > 1) {
        let pushyarr = [];
        for (let u = 0; u < systemarr.meta.pagination.pageCount; u++) {
          pushyarr.push(u);
        }

        return (
          <>
            <button
              className={page > 1 ? '' : 'hidden'}
              onClick={() => {
                setpage((page = page - 1));
              }}
            >
              <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M10.5302 12.4701C10.6711 12.6109 10.7502 12.802 10.7502 13.0013C10.7502 13.2006 10.6711 13.3917 10.5302 13.5326C10.3893 13.6734 10.1982 13.7526 9.99891 13.7526C9.79965 13.7526 9.60856 13.6734 9.46766 13.5326L4.46766 8.53255C4.39774 8.46287 4.34226 8.38008 4.30441 8.28891C4.26656 8.19775 4.24707 8.10001 4.24707 8.0013C4.24707 7.90259 4.26656 7.80485 4.30441 7.71369C4.34226 7.62252 4.39774 7.53973 4.46766 7.47005L9.46766 2.47005C9.60856 2.32915 9.79965 2.25 9.99891 2.25C10.1982 2.25 10.3893 2.32915 10.5302 2.47005C10.6711 2.61095 10.7502 2.80204 10.7502 3.0013C10.7502 3.20056 10.6711 3.39165 10.5302 3.53255L6.06204 8.00068L10.5302 12.4701Z"
                  fill="black"
                />
              </svg>
            </button>
            {pushyarr.map((item) => {
              return (
                <>
                  <button className={page == item + 1 ? 'ActivePageButton' : ''} onClick={() => setpage(item + 1)}>
                    {item + 1}
                  </button>
                </>
              );
            })}
            <button
              className={page < systemarr.meta.pagination.pageCount ? '' : 'hidden'}
              onClick={() => {
                setpage((page = page + 1));
              }}
            >
              <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M6.5306 6.5306L1.5306 11.5306C1.3897 11.6715 1.19861 11.7506 0.999348 11.7506C0.80009 11.7506 0.608994 11.6715 0.468098 11.5306C0.327201 11.3897 0.248047 11.1986 0.248047 10.9993C0.248047 10.8001 0.327201 10.609 0.468098 10.4681L4.93747 5.99997L0.469348 1.5306C0.399583 1.46083 0.344243 1.37801 0.306486 1.28686C0.26873 1.19571 0.249297 1.09801 0.249297 0.999348C0.249297 0.900686 0.26873 0.802989 0.306486 0.711837C0.344243 0.620685 0.399583 0.537863 0.469348 0.468098C0.539113 0.398333 0.621936 0.342993 0.713088 0.305236C0.80424 0.26748 0.901936 0.248047 1.0006 0.248047C1.09926 0.248047 1.19696 0.26748 1.28811 0.305236C1.37926 0.342993 1.46208 0.398333 1.53185 0.468098L6.53185 5.4681C6.60169 5.53786 6.65707 5.62072 6.69481 5.71193C6.73256 5.80313 6.75193 5.9009 6.75181 5.99961C6.7517 6.09832 6.7321 6.19603 6.69414 6.28715C6.65617 6.37827 6.6006 6.461 6.5306 6.5306Z"
                  fill="black"
                />
              </svg>
            </button>
          </>
        );
      }
    }
  }

  function reloadgrid() {
    if (systemarr.data.length > 0) {
      return (
        <>
          {systemarr.data.map((item, index) => {
            let switcheroo;
            let splitswitcheroo;
            let word;
            if (item.attributes.Date) {
              if (para.count != 'en-us' && para.count != 'es-us') {
                switcheroo = item.attributes.Date;
                splitswitcheroo = switcheroo.split('-');
                word = splitswitcheroo[2] + '-' + splitswitcheroo[1] + '-' + splitswitcheroo[0];
                switcheroo = word;
              } else {
                switcheroo = item.attributes.Date;
                splitswitcheroo = switcheroo.split('-');
                word = splitswitcheroo[1] + '-' + splitswitcheroo[2] + '-' + splitswitcheroo[0];
                switcheroo = word;
              }
            }

            return (
              <>
                <div key={'KEY' + index} className="systemgrid-card NewsOverview-card">
                  <Link hrefLang={param.count} to={'/' + para.count + '/news/' + item.attributes.Slug}>
                    <img
                      className=""
                      alt="System-Image"
                      src={item.attributes.CardImage3x2.data.attributes.url}
                      width="312"
                      height="208"
                    />
                    <h3>{item.attributes.Title}</h3>
                    <h6>{switcheroo}</h6>
                    <p>{item.attributes.CardDescription}</p>
                  </Link>
                </div>
              </>
            );
          })}
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{translatedTextTypeHere[0]}</title>
        <meta name="description" content={translatedTextTypeHere[1]}></meta>

        <link rel="alternate" hreflang="da-DK" href="https://q-railing.com/da-dk/news/" />
        <link rel="alternate" hreflang="de-AT" href="https://q-railing.com/de-at/news/" />
        <link rel="alternate" hreflang="de-CH" href="https://q-railing.com/de-ch/news/" />
        <link rel="alternate" hreflang="de-DE" href="https://q-railing.com/de-de/news/" />
        <link rel="alternate" hreflang="x-default" href="https://q-railing.com/en/news/" />
        <link rel="alternate" hreflang="en-CA" href="https://q-railing.com/en-ca/news/" />
        <link rel="alternate" hreflang="en-AT" href="https://q-railing.com/en-at/news/" />
        <link rel="alternate" hreflang="en-GB" href="https://q-railing.com/en-gb/news/" />
        <link rel="alternate" hreflang="en-IE" href="https://q-railing.com/en-ie/news/" />
        <link rel="alternate" hreflang="en-US" href="https://q-railing.com/en-us/news/" />
        <link rel="alternate" hreflang="es" href="https://q-railing.com/es/news/" />
        <link rel="alternate" hreflang="es-ES" href="https://q-railing.com/es-es/news/" />
        <link rel="alternate" hreflang="es-US" href="https://q-railing.com/es-us/news/" />
        <link rel="alternate" hreflang="fi-FI" href="https://q-railing.com/fi-fi/news/" />
        <link rel="alternate" hreflang="fr-BE" href="https://q-railing.com/fr-be/news/" />
        <link rel="alternate" hreflang="fr-CA" href="https://q-railing.com/fr-ca/news/" />
        <link rel="alternate" hreflang="fr-CH" href="https://q-railing.com/fr-ch/news/" />
        <link rel="alternate" hreflang="fr-FR" href="https://q-railing.com/fr-fr/news/" />
        <link rel="alternate" hreflang="fr-LU" href="https://q-railing.com/fr-lu/news/" />
        <link rel="alternate" hreflang="it-CH" href="https://q-railing.com/it-ch/news/" />
        <link rel="alternate" hreflang="it-IT" href="https://q-railing.com/it-it/news/" />
        <link rel="alternate" hreflang="nb-NO" href="https://q-railing.com/nb-no/news/" />
        <link rel="alternate" hreflang="nl-BE" href="https://q-railing.com/nl-be/news/" />
        <link rel="alternate" hreflang="nl-NL" href="https://q-railing.com/nl-nl/news/" />
        <link rel="alternate" hreflang="pl-PL" href="https://q-railing.com/pl-pl/news/" />
        <link rel="alternate" hreflang="pt-BR" href="https://q-railing.com/pt-br/news/" />
        <link rel="alternate" hreflang="pt-PT" href="https://q-railing.com/pt-pt/news/" />
        <link rel="alternate" hreflang="sv-SE" href="https://q-railing.com/sv-se/news/" />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="NewsOverview-wrap">
        <div className="NewsOverview-header">
          <h2>
            {pagetranslations.data.length > 0 ? pagetranslations.data[0].attributes.FreeData1.NewsOverview[0] : 'News'}
          </h2>
          <p>
            {pagetranslations.data.length > 0
              ? pagetranslations.data[0].attributes.FreeData1.NewsOverview[1]
              : 'Here you can find a collection of our latest news and publications'}
          </p>
        </div>
      </div>
      <div className="systemgrid-wrap">
        <div className="systemgrid-inner NewsOverview-inner">
          <div className="systemgrid-head"></div>
          <div className="systemgrid-grid NewsOverview-grid">{reloadgrid()}</div>
          <div className="NewsOverview-pagination">{loadpage()}</div>
        </div>
      </div>
      <NewsletterNew color="grey"></NewsletterNew>
      <Footernew></Footernew>
    </>
  );
}

export default NewsOverview;
