import React, { useEffect } from 'react';

import { useNavigate } from 'react-router-dom';
import Links from './includes/Links.json';
import ShopLinks from './includes/LinksShop.json';

function Permalink() {
  const navigate = useNavigate();
  let locale = 'en';
  let slughead = '';

  const redirect = (route, historyOverwrite = true) => {
    console.log('redirecting to', route);
    if (!route.endsWith('/')) route += '/';
    // remove current link from history to prevent back button from redirecting to the same link
    navigate(route, { replace: historyOverwrite });
  };

  useEffect(() => {
    fetch('https://api.ipify.org?format=json')
      .then((response) => response.json())
      .then((data) => {
        console.log('data:', data);
        fetch('https://strapi-qrailing.azurewebsites.net/api/ipredir?' + data.ip)
          .then((response) => response.json())
          .then((data) => {
            let codee = data.countryCode.toLowerCase();
            localStorage.setItem('i18n-overwrite-bool', 'true');
            codee = codee.toLowerCase();
            localStorage.setItem('RedirectBool', 'true');

            const localemap = {
              da: 'da-dk',
              ch: 'de-ch',
              de: 'de-de',
              at: 'de-at',
              ca: 'en-ca',
              gb: 'en-gb',
              ie: 'en-ie',
              us: 'en-us',
              es: 'es-es',
              fi: 'fi-fi',
              fr: 'fr-fr',
              lu: 'fr-lu',
              it: 'it-it',
              no: 'nb-no',
              nl: 'nl-nl',
              be: 'nl-be',
              pl: 'pl-pl',
              br: 'pt-br',
              pt: 'pt-pt',
              se: 'sv-se',
              hr: 'en-at',
              hu: 'en-at',
              rs: 'en-at',
              ba: 'en-at',
              me: 'en-at',
              ro: 'en-at',
              bg: 'en-at',
              gr: 'en-at',
              sk: 'en-at',
              cz: 'en-at',
            };

            if (localemap[codee]) {
              locale = localemap[codee];
            } else {
              locale = 'en';
            }
            let url = window.location.href;
            if (url.match(/\?/) || url.match('q-disc')) {
              if (url.match('q-disc')) {
                redirect('/' + locale + '/q-disc/');
              }

              let inputs = url.split('?');
              let numberss = 1;
              if (inputs[1].match('action=system') || inputs[1].match('action=systems')) {
                numberss = 8;
              }
              if (inputs[1].match('action=configurator') || inputs[1].match('action=configurators')) {
                numberss = 1;
              }

              //Listee

              if (inputs[1].match('action=gelaender') || inputs[1].match('action=railings')) {
                numberss = 2;
              }
              if (inputs[1].match('action=glasgelaender') || inputs[1].match('action=glassrailings')) {
                numberss = 3;
              }
              if (inputs[1].match('action=treppen') || inputs[1].match('action=stairs')) {
                numberss = 4;
              }
              if (inputs[1].match('action=handrail') || inputs[1].match('action=gelaender')) {
                numberss = 5;
              }
              if (inputs[1].match('action=balkon') || inputs[1].match('action=balcony')) {
                numberss = 6;
              }
              if (inputs[1].match('action=franzbalkon') || inputs[1].match('action=frenchbalcony')) {
                numberss = 7;
              }
              if (inputs[1].match('action=basechannel') || inputs[1].match('action=baseshoe')) {
                numberss = 59;
              }
              if (inputs[1].match('action=glasclamp') || inputs[1].match('action=clamps')) {
                numberss = 60;
              }
              if (inputs[1].match('action=adapter') || inputs[1].match('action=adapters')) {
                numberss = 61;
              }

              let actions = [inputs[1]];

              if (inputs[1].match(';')) {
                actions = inputs[1].split(';');
              }

              const hasSlug = actions.some((action) => action.includes('slug='));

              if (hasSlug) {
                let slug = actions.filter((action) => action.includes('slug='));
                let slugss = slug[0].split('=');
                let slugs = slugss[1];
                slughead = slugs;
              }

              const Localemap = {
                'da-dk': 'da_arr',
                'de-at': 'de_arr',
                'de-ch': 'de_arr',
                'de-de': 'de_arr',
                'en-at': 'en_arr',
                'en-ca': 'en_arr',
                en: 'en_arr',
                'en-gb': 'en_arr',
                'en-ie': 'en_arr',
                'en-us': 'en_us_arr',
                es: 'es_arr',
                'es-es': 'es_arr',
                'es-us': 'es_arr',
                'fi-fi': 'fi_arr',
                'fr-be': 'fr_arr',
                'fr-ca': 'fr_arr',
                'fr-fr': 'fr_arr',
                'fr-ch': 'fr_arr',
                'fr-lu': 'fr_arr',
                'it-ch': 'it_arr',
                'it-it': 'it_arr',
                'nb-no': 'nb_arr',
                'nl-nl': 'nl_arr',
                'nl-be': 'nl_arr',
                'pl-pl': 'pl_arr',
                'pt-br': 'pt_arr',
                'pt-pt': 'pt_arr',
                'sv-se': 'sv_arr',
              };

              let linky;
              let shoplink;
              if (Links.data[Localemap[locale]][numberss]) {
                linky = Links.data[Localemap[locale]][numberss];
              }

              if (inputs[1].match('action=configentry') && inputs[1].match('system=bc')) {
                linky = Links.data[Localemap[locale]][1] + '?bc';
              }
              if (inputs[1].match('action=configentry') && inputs[1].match('system=egs')) {
                linky = Links.data[Localemap[locale]][1] + '?egs';
              }
              if (inputs[1].match('action=configentry') && inputs[1].match('system=ea')) {
                linky = Links.data[Localemap[locale]][1] + '?ea';
              }
              if (inputs[1].match('action=configentry') && inputs[1].match('system=ep')) {
                linky = Links.data[Localemap[locale]][1] + '?ep';
              }
              if (inputs[1].match('action=configentry') && inputs[1].match('system=fb')) {
                linky = Links.data[Localemap[locale]][1] + '?fb';
              }

              if (inputs[1].match('action=projects') || inputs[1].match('action=project')) {
                linky = '/projects/';
              }

              if (inputs[1].match('action=q-disc') || inputs[1].match('q-disc') || url.match('q-disc')) {
                linky = '/q-disc/';
              }
              if (inputs[1].match('action=downloads') || inputs[1].match('action=download')) {
                linky = '/service/downloads/';
              }
              if (inputs[1].match('action=training') || inputs[1].match('action=trainings')) {
                linky = '/service/trainings/';
              }
              if (inputs[1].match('prefix') || inputs[1].match('action=prefix')) {
                linky = '/service/prefix/';
              }
              if (inputs[1].match('action=shop') || inputs[1].match('action=products')) {
                linky = false;
                shoplink = ShopLinks.data[locale.replace('-', '_')];
              }

              if (inputs[1].match('product_id=')) {
                linky = false;
                const index = inputs[1].indexOf('product_id=') + 10;
                const sixNumbers = inputs[1].substring(index + 1, index + 1 + 6);
                slughead = sixNumbers;
                shoplink = ShopLinks.data[locale.replace('-', '_')];
              }

              if (inputs[1].match('system_id=')) {
                const index = inputs[1].indexOf('system_id=') + 9;
                let sixNumbers = inputs[1].substring(index + 1, index + 1 + 3);
                if (sixNumbers.match('&')) {
                  let splitter = sixNumbers.split('&');
                  sixNumbers = splitter[0];
                }
                const systemma = {
                  125: 'easy-alu',
                  123: 'barrier-line',
                  113: 'easy-glass-smart',
                  121: 'easy-glass-air',
                  10: 'easy-glass-slim',
                  75: 'easy-glass-prime',
                  119: 'easy-glass-prime',
                  93: 'easy-glass-up',
                  25: 'easy-glass-mod-0763',
                  36: 'q-lights-linear-light',
                  39: 'q-lights-spotlight',
                  6: 'easy-glass-max',
                  19: 'easy-glass-mod-6000-6400',
                  12: 'easy-glass-mod-6507-6508',
                  133: 'easy-glass-mod-4762',
                  139: 'naturail',
                  141: 'easy-glass-smart-plus',
                  143: 'easy-glass-strong',
                  145: 'square-line-baluster-railing',
                  16: 'd-line-blok-easy-glass',
                  22: 'easy-glass-mod-0760-0766',
                  29: 'easy-glass-mod-0745-0749',
                  47: 'square-line-baluster-railing',
                  62: 'square-line-baluster-railing',
                  71: 'square-line-handrails',
                  74: 'easy-glass-hybrid',
                  97: 'easy-glass-view',
                  77: 'q-line',
                };
                linky = Links.data[Localemap[locale]][8];
                slughead = systemma[parseInt(sixNumbers)];
              }
              if (linky) {
                setTimeout(() => {
                  redirect('/' + locale + linky + slughead);
                }, 400);
              }
              if (shoplink) {
                setTimeout(() => {
                  redirect(shoplink + '/' + slughead);
                }, 400);
              } else {
                redirect('/' + locale);
              }
            } else {
              redirect('/' + locale);
            }
          });
      });
  }, []);

  return (
    <>
      <div className="Q-railing-Intro-wrap">
        <div className="Q-railing-Intro-inner">
          <div id="Q-railing-loading">
            <img
              id="Q-railing-logo"
              className="jump-and-scale"
              src="https://strapiwmc4hstore.blob.core.windows.net/website-assets/q_logo_white.webp"
              width="72"
              height={'72'}
              alt="Q-railing logo"
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Permalink;
