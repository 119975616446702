import { useState, useEffect, useRef } from 'react';
import { Link, useLocation, useParams } from 'react-router-dom';
import './Registerform.css';
import Footernew from './Footernew';
import HeaderSlim from './HeaderSlim';
import NeedHelp from './NeedHelp';
import { Helmet } from 'react-helmet';

function Registerform() {
  let [sysdata, setsysdata] = useState({ data: [] });
  let [alrcust, setalrcust] = useState(false);
  let [registerstep, setregisterstep] = useState(1);
  let param = useParams();
  let [namepara, setnamepara] = useState('');
  let la;
  let arrla;
  let arrla1 = '';
  const fileInputRef = useRef(null);
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location, registerstep]);

  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/components?locale=' + arrla1 + '&filters[Slug][$eq]=RegisterForm',
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsysdata(json);
      });
  }, []);

  useEffect(() => {
    if (param.count) {
      if (param.count == 'de-ch' || param.count == 'en-ch' || param.count == 'fr-ch' || param.count == 'it-ch') {
        window.location.replace('/' + param.count + '/404');
      }
    }
  }, []);

  function sendregisterdata(whatzup) {
    if (whatzup == 'notalrcust') {
      let Errorwarning = '';
      let warning = document.getElementById('senderror-text');
      let salutation = document.getElementById('register-form-salutation').value;
      let firstname = document.getElementById('register-form-first-name').value;
      let country = document.getElementById('register-form-country').value;
      let city = document.getElementById('register-form-city').value;
      let email = document.getElementById('register-form-email').value;
      let lastname = document.getElementById('register-form-last-name').value;
      let address = document.getElementById('register-form-address').value;
      let zipcode = document.getElementById('register-form-zipcode').value;
      let companyname = document.getElementById('register-form-company').value;
      let position = document.getElementById('register-form-position').value;
      let jobtitle = document.getElementById('register-form-jobtitle').value;
      let phone = document.getElementById('register-form-phone').value;
      let industygroup = document.getElementById('register-form-industygroup').value;
      let vat = document.getElementById('register-form-vat').value;

      console.log(zipcode);
      if (email.length < 1) {
        Errorwarning = 'Please enter an e-mail';
        warning.innerText = Errorwarning;
        return;
      }

      if (vat.length < 1 && param.count == 'fi-fi') {
        Errorwarning = 'Syötä alv-tunnuksesi';
        warning.innerText = Errorwarning;
        return;
      }
      if (phone.length < 1 || !/[a-zA-Z0-9]/.test(phone)) {
        Errorwarning = 'Please enter a valid phone number';
        warning.innerText = Errorwarning;
        return;
      }
      if (companyname.length < 1 || !/[a-zA-Z0-9]/.test(companyname)) {
        Errorwarning = 'Please enter a valid company name';
        warning.innerText = Errorwarning;
        return;
      }
      if (zipcode.length < 1 || !/[a-zA-Z0-9]/.test(zipcode)) {
        Errorwarning = 'Please enter a valid zipcode';
        warning.innerText = Errorwarning;
        return;
      }
      if (city.length < 1 || !/[a-zA-Z0-9]/.test(city)) {
        Errorwarning = 'Please enter a valid city';
        warning.innerText = Errorwarning;
        return;
      }
      if (country.length < 1 || !/[a-zA-Z0-9]/.test(country)) {
        Errorwarning = 'Please enter a valid country';
        warning.innerText = Errorwarning;
        return;
      }
      if (address.length < 1 || !/[a-zA-Z0-9]/.test(address)) {
        Errorwarning = 'Please enter an address';
        warning.innerText = Errorwarning;
        return;
      }
      if (!email.match('@') || !email.match('.')) {
        Errorwarning = 'Please enter a valid e-mail';
        warning.innerText = Errorwarning;
        return;
      }

      if (param.count == 'da-dk' && vat.length < 1) {
        Errorwarning = 'Indtast venligst dit momsnummer';
        warning.innerText = Errorwarning;
        return;
      }
      //Anpassungen EN-GB extrawurst

      if ((param.count == 'en-gb' || param.count == 'en-ie') && vat.length < 1) {
        Errorwarning = 'please enter your VAT number';
        warning.innerText = Errorwarning;
        return;
      }

      if ((param.count == 'en-gb' || param.count == 'en-ie') && lastname.length < 1) {
        Errorwarning = 'Please enter your last name';
        warning.innerText = Errorwarning;
        return;
      }

      if ((param.count == 'en-gb' || param.count == 'en-ie') && firstname.length < 1) {
        Errorwarning = 'Please enter your first name';
        warning.innerText = Errorwarning;
        return;
      }
      if ((param.count == 'en-gb' || param.count == 'en-ie') && industygroup == '-') {
        Errorwarning = 'Please select an industry group';
        warning.innerText = Errorwarning;
        return;
      }

      let LocaleByPage = param.count;
      Errorwarning = '';
      warning.innerText = Errorwarning;
      let combistuff = firstname + ' ' + lastname + ' ' + zipcode + ' ' + address + ' ' + country;

      let reqbody = JSON.stringify({
        data: {
          Email: email,
          Salutation: salutation,
          CompanyName: companyname,
          CustomerId: combistuff,
          FirstName: firstname,
          LastName: lastname,
          Phone: phone,
          City: city,
          Vat: vat,
          Street: address,
          IndustryGroup: industygroup,
          Country: country,
          Locale: LocaleByPage,
          PostCode: zipcode,
          JobTitle: jobtitle,
          JobPosition: position,
        },
      });

      const requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: reqbody,
      };

      fetch('https://strapi-qrailing.azurewebsites.net/api/shop-account-requests', requestTextOptions)
        .then((response) => response.json())
        .then(
          setTimeout(() => {
            console.log('###########sleep#############');
          }, 2000),
        )
        .then((data) => this.setState({ postId: data.id }));
      setnamepara(firstname + '_' + lastname);
      setregisterstep(2);
    }

    if (whatzup == 'alrcust') {
      let Errorwarning = '';
      let warning = document.getElementById('senderror-text');

      let custid = document.getElementById('register-form-customer-id').value;
      let email = document.getElementById('register-form-email').value;
      let companyname = document.getElementById('register-form-company').value;

      if (email.length < 1) {
        Errorwarning = 'Please enter an e-mail';
        warning.innerText = Errorwarning;
        return;
      }
      if (companyname.length < 1) {
        Errorwarning = 'Please enter your company name';
        warning.innerText = Errorwarning;
        return;
      }

      if (!email.match('@') || !email.match('.')) {
        Errorwarning = 'Please enter a valid e-mail';
        warning.innerText = Errorwarning;
        return;
      }
      if (!custid.match(/\d\d-\d\d\d\d\d\d/) || custid.match(/\d\d-\d\d\d\d\d\d\d/)) {
        Errorwarning = 'Please enter a valid CustomerID';
        warning.innerText = Errorwarning;
        return;
      }

      let LocaleByPage = param.count;
      Errorwarning = '';
      warning.innerText = Errorwarning;

      let reqbody = JSON.stringify({
        data: {
          Email: email,
          CustomerId: custid,
          Vat: custid,
          Country: LocaleByPage,
          Locale: LocaleByPage,
          CompanyName: companyname,
        },
      });

      const requestTextOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
        body: reqbody,
      };

      fetch('https://strapi-qrailing.azurewebsites.net/api/shop-account-requests', requestTextOptions)
        .then((response) => response.json())
        .then(
          setTimeout(() => {
            console.log('###########sleep#############');
          }, 2000),
        )
        .then((data) => this.setState({ postId: data.id }));
      setregisterstep(3);
    }
  }

  function sendDocs() {
    const file = fileInputRef.current.files[0];
    let warning = document.getElementById('senderror-text');
    let Errorwarning = '';
    if (!file) {
      Errorwarning = 'Please add a your documents as a PDF';
      warning.innerText = Errorwarning;
      return;
    }

    if (!file.name.match('.pdf')) {
      Errorwarning = 'Please upload the documents in pdf-format';
      warning.innerText = Errorwarning;
      return;
    }

    Errorwarning = '';
    warning.innerText = Errorwarning;
    const now = new Date();

    const day = String(now.getDate()).padStart(2, '0');
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
    const year = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');

    const timestamp = `${day}/${month}/${year} ${hours}:${minutes}:${seconds}`;

    const formData = new FormData();
    formData.append('files', file, namepara + '_BUSINESSLICENSE_' + timestamp); // Use "files" as the key

    const requestOptions = {
      method: 'POST',
      headers: { Authorization: `Bearer ${token}` },
      body: formData,
    };

    fetch('https://strapi-qrailing.azurewebsites.net/api/upload', requestOptions)
      .then((response) => response.json())
      .then((data) => {
        console.log('File uploaded:', data);
        // Handle success or further actions
        setregisterstep(3);
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        // Handle error
      });
  }

  return (
    <>
      <Helmet>
        <title>{sysdata.data.length > 0 ? sysdata.data[0].attributes.MetaTitle : 'Create Account'}</title>
        <meta
          name="description"
          content={sysdata.data.length > 0 ? sysdata.data[0].attributes.MetaText : 'Q-railing Shop account register'}
        ></meta>

        <link rel="alternate" hreflang="da-DK" href="/da-dk/register/" />
        <link rel="alternate" hreflang="de-AT" href="/de-at/register/" />
        <link rel="alternate" hreflang="de-CH" href="/de-ch/register/" />
        <link rel="alternate" hreflang="de-DE" href="/de-de/register/" />
        <link rel="alternate" hreflang="x-default" href="/en/register/" />
        <link rel="alternate" hreflang="en-CA" href="/en-ca/register/" />
        <link rel="alternate" hreflang="en-GB" href="/en-gb/register/" />
        <link rel="alternate" hreflang="en-IE" href="/en-ie/register/" />
        <link rel="alternate" hreflang="en-US" href="/en-us/register/" />
        <link rel="alternate" hreflang="es" href="/es/register/" />
        <link rel="alternate" hreflang="es-ES" href="/es-es/register/" />
        <link rel="alternate" hreflang="es-US" href="/es-us/register/" />
        <link rel="alternate" hreflang="fi-FI" href="/fi-fi/register/" />
        <link rel="alternate" hreflang="fr-BE" href="/fr-be/register/" />
        <link rel="alternate" hreflang="fr-CA" href="/fr-ca/register/" />
        <link rel="alternate" hreflang="fr-CH" href="/fr-ch/register/" />
        <link rel="alternate" hreflang="fr-FR" href="/fr-fr/register/" />
        <link rel="alternate" hreflang="fr-LU" href="/fr-lu/register/" />
        <link rel="alternate" hreflang="it-CH" href="/it-ch/register/" />
        <link rel="alternate" hreflang="it-IT" href="/it-it/register/" />
        <link rel="alternate" hreflang="nb-NO" href="/nb-no/register/" />
        <link rel="alternate" hreflang="nl-NL" href="/nl-be/register/" />
        <link rel="alternate" hreflang="nl-NL" href="/nl-nl/register/" />
        <link rel="alternate" hreflang="pl-PL" href="/pl-pl/register/" />
        <link rel="alternate" hreflang="pt-BR" href="/pt-br/register/" />
        <link rel="alternate" hreflang="pt-PT" href="/pt-pt/register/" />
        <link rel="alternate" hreflang="sv-SE" href="/sv-se/register/" />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="Registerform-wrap">
        <form
          className="Registerform-inner"
          id="register-form"
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {registerstep == 1 && (
            <>
              <div className="jobcenter-header">
                <h1>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeTitle1}</h1>
                <div className="jobcenter-header-subheader">
                  <p>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeText1}</p>

                  <div className="jobcenter-header-subheader-inner">
                    <h3>{sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations[4]}</h3>
                  </div>
                </div>
              </div>
              <div className="Registerform-inner-middle-headline">
                <h3>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeTitle2}</h3>
              </div>

              <div className="Registerform-attributes-wrap">
                {sysdata.data.length > 0 &&
                  sysdata.data[0].attributes.FreeData3.data.map((item) => {
                    return (
                      <div className="Registerform-attributes">
                        <svg width="13" height="9" viewBox="0 0 13 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path
                            d="M12.354 0.854028L4.35403 8.85403C4.30759 8.90052 4.25245 8.9374 4.19175 8.96256C4.13105 8.98772 4.06599 9.00067 4.00028 9.00067C3.93457 9.00067 3.86951 8.98772 3.80881 8.96256C3.74811 8.9374 3.69296 8.90052 3.64653 8.85403L0.146528 5.35403C0.0527077 5.26021 0 5.13296 0 5.00028C0 4.8676 0.0527077 4.74035 0.146528 4.64653C0.240348 4.55271 0.367596 4.5 0.500278 4.5C0.63296 4.5 0.760208 4.55271 0.854028 4.64653L4.00028 7.7934L11.6465 0.146528C11.7403 0.0527074 11.8676 -9.88557e-10 12.0003 0C12.133 9.88558e-10 12.2602 0.0527074 12.354 0.146528C12.4479 0.240348 12.5006 0.367596 12.5006 0.500278C12.5006 0.63296 12.4479 0.760208 12.354 0.854028Z"
                            fill="black"
                          />
                        </svg>
                        <span>{item}</span>
                      </div>
                    );
                  })}
              </div>
              {alrcust && (
                <div className="Registerform-radiobuttons">
                  <label className="custom-radio">
                    <span className="Registerform-RadioButtonText">
                      {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[0]}
                    </span>
                    <input
                      type="radio"
                      className="radioinput"
                      name="radio"
                      id="businessid"
                      checked
                      onChange={() => setalrcust(true)}
                    />
                    <span className="spacer">
                      {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[1]}
                    </span>
                  </label>
                  <label className="custom-radio">
                    <input type="radio" className="radioinput" name="radio" onChange={() => setalrcust(false)} />
                    <span className="spacer">
                      {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[2]}
                    </span>
                  </label>
                </div>
              )}

              {!alrcust && (
                <div className="Registerform-radiobuttons">
                  <span className="Registerform-RadioButtonText">
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[0]}
                  </span>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      className="radioinput"
                      name="radio"
                      id="businessid"
                      onChange={() => setalrcust(true)}
                    />
                    <span className="spacer">
                      {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[1]}
                    </span>
                  </label>
                  <label className="custom-radio">
                    <input
                      type="radio"
                      className="radioinput"
                      name="radio"
                      checked
                      onChange={() => setalrcust(false)}
                    />
                    <span className="spacer">
                      {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[2]}
                    </span>
                  </label>
                </div>
              )}
              {!alrcust && (
                <>
                  <div className="Registerform-inputs">
                    {param.count != 'da-dk' && (
                      <label>
                        <h4>
                          {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[10]}
                          <span>
                            {sysdata.data.length > 0 ? sysdata.data[0].attributes.FreeData1.data[15] : '(optional)'}
                          </span>
                        </h4>
                        <select id="register-form-salutation">
                          {sysdata.data.length > 0 &&
                            sysdata.data[0].attributes.FreeData5.data.map((item) => {
                              return <option value={item}>{item}</option>;
                            })}
                        </select>
                      </label>
                    )}
                    <label>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[0]}
                        <span>
                          {sysdata.data.length > 0 &&
                            param.count != 'en-gb' &&
                            sysdata.data[0].attributes.FreeData1.data[15]}
                        </span>
                      </h4>
                      <input type="text" id="register-form-first-name" />
                    </label>
                    <label>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[1]}
                        <span>
                          {sysdata.data.length > 0 &&
                            param.count != 'en-gb' &&
                            sysdata.data[0].attributes.FreeData1.data[15]}
                        </span>
                      </h4>
                      <input type="text" id="register-form-last-name" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[5]}</h4>
                      <input type="text" id="register-form-email" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[9]}</h4>
                      <input type="text" id="register-form-phone" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[7]}</h4>
                      <input type="text" id="register-form-company" />
                    </label>

                    <label>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[11]}
                        <span>
                          {sysdata.data.length > 0 &&
                            param.count != 'en-gb' &&
                            sysdata.data[0].attributes.FreeData1.data[15]}
                        </span>
                      </h4>
                      <select id="register-form-industygroup">
                        {sysdata.data.length > 0 &&
                          sysdata.data[0].attributes.FreeData4.data.map((item) => {
                            return <option value={item}>{item}</option>;
                          })}
                      </select>
                    </label>
                    <label>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[12]}
                        <span>
                          {sysdata.data.length > 0 &&
                            param.count != 'fi-fi' &&
                            param.count != 'da-dk' &&
                            param.count != 'en-gb' &&
                            sysdata.data[0].attributes.FreeData1.data[15]}
                        </span>
                      </h4>
                      <input type="text" id="register-form-vat" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[2]}</h4>
                      <input type="text" id="register-form-address" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[3]}</h4>
                      <input type="text" id="register-form-zipcode" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[14]}</h4>
                      <input type="text" id="register-form-city" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[4]}</h4>
                      <input type="text" id="register-form-country" />
                    </label>
                    <label className={param.count != 'en-ie' && param.count != 'en-gb' && 'hidden'}>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[17]}
                        <span>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[15]}</span>
                      </h4>
                      <input type="text" id="register-form-jobtitle" />
                    </label>
                    <label className={param.count != 'en-ie' && param.count != 'en-gb' && 'hidden'}>
                      <h4>
                        {sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[16]}
                        <span>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[15]}</span>
                      </h4>
                      <input type="text" id="register-form-position" />
                    </label>
                  </div>
                  <button id="RegisterSubmit" onClick={() => sendregisterdata('notalrcust')}>
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[3]}
                  </button>
                </>
              )}

              {alrcust && (
                <>
                  <div className="Registerform-inputs">
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[6]}</h4>
                      <input type="text" id="register-form-customer-id" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[7]}</h4>
                      <input type="text" id="register-form-company" />
                    </label>
                    <label>
                      <h4>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeData1.data[5]}</h4>
                      <input type="text" id="register-form-email" />
                    </label>
                  </div>
                  <button id="RegisterSubmit" onClick={() => sendregisterdata('alrcust')}>
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[3]}
                  </button>
                </>
              )}

              <p id="senderror-text"></p>
            </>
          )}

          {registerstep == 2 && param.count != 'de-de' && param.count != 'nl-nl' && (
            <div className="Registerform-step2-wrap">
              <div className="Registerform-SuccesButton-wrap">
                <span className="Registerform-SuccesButton">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.7969 7.54695L9.79687 19.547C9.69236 19.6518 9.56816 19.735 9.43142 19.7918C9.29467 19.8486 9.14806 19.8778 9 19.8778C8.85193 19.8778 8.70532 19.8486 8.56858 19.7918C8.43183 19.735 8.30764 19.6518 8.20312 19.547L2.95312 14.297C2.84848 14.1923 2.76547 14.0681 2.70883 13.9313C2.6522 13.7946 2.62305 13.6481 2.62305 13.5001C2.62305 13.3521 2.6522 13.2055 2.70883 13.0688C2.76547 12.9321 2.84848 12.8078 2.95312 12.7032C3.05777 12.5986 3.182 12.5155 3.31873 12.4589C3.45546 12.4023 3.60201 12.3731 3.75 12.3731C3.89799 12.3731 4.04454 12.4023 4.18126 12.4589C4.31799 12.5155 4.44223 12.5986 4.54687 12.7032L9.00094 17.1573L20.205 5.95508C20.4163 5.74373 20.703 5.625 21.0019 5.625C21.3008 5.625 21.5874 5.74373 21.7987 5.95508C22.0101 6.16642 22.1288 6.45307 22.1288 6.75195C22.1288 7.05084 22.0101 7.33748 21.7987 7.54883L21.7969 7.54695Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>

              <div className="Registerform-step2-text-wrap">
                <div className="Registerform-step2-text-wrap-inner">
                  <h2>{sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[6]}</h2>
                  <p>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeText2}</p>
                  <Link to={'/' + param.count + '/'}>
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[5]}
                  </Link>
                </div>
              </div>
            </div>
          )}

          {registerstep == 2 && (param.count == 'de-de' || param.count == 'nl-nl') && (
            <div className="Registerform-step2-wrap">
              <div className="Registerform-SuccesButton-wrap">
                <span className="Registerform-SuccesButton">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.7969 7.54695L9.79687 19.547C9.69236 19.6518 9.56816 19.735 9.43142 19.7918C9.29467 19.8486 9.14806 19.8778 9 19.8778C8.85193 19.8778 8.70532 19.8486 8.56858 19.7918C8.43183 19.735 8.30764 19.6518 8.20312 19.547L2.95312 14.297C2.84848 14.1923 2.76547 14.0681 2.70883 13.9313C2.6522 13.7946 2.62305 13.6481 2.62305 13.5001C2.62305 13.3521 2.6522 13.2055 2.70883 13.0688C2.76547 12.9321 2.84848 12.8078 2.95312 12.7032C3.05777 12.5986 3.182 12.5155 3.31873 12.4589C3.45546 12.4023 3.60201 12.3731 3.75 12.3731C3.89799 12.3731 4.04454 12.4023 4.18126 12.4589C4.31799 12.5155 4.44223 12.5986 4.54687 12.7032L9.00094 17.1573L20.205 5.95508C20.4163 5.74373 20.703 5.625 21.0019 5.625C21.3008 5.625 21.5874 5.74373 21.7987 5.95508C22.0101 6.16642 22.1288 6.45307 22.1288 6.75195C22.1288 7.05084 22.0101 7.33748 21.7987 7.54883L21.7969 7.54695Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>

              <div className="Registerform-step2-text-wrap">
                <div className="Registerform-step2-text-wrap-inner Registerform-step2-text-wrap-inner2">
                  <h2>{sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[7]}</h2>
                  <p>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeText2}</p>
                  <label id="FormFileBlock-Document-label" for="FormFileBlock-Document">
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[9]}{' '}
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ffffff" viewBox="0 0 256 256">
                      <path d="M240,136v64a16,16,0,0,1-16,16H32a16,16,0,0,1-16-16V136a16,16,0,0,1,16-16H80a8,8,0,0,1,0,16H32v64H224V136H176a8,8,0,0,1,0-16h48A16,16,0,0,1,240,136ZM85.66,77.66,120,43.31V128a8,8,0,0,0,16,0V43.31l34.34,34.35a8,8,0,0,0,11.32-11.32l-48-48a8,8,0,0,0-11.32,0l-48,48A8,8,0,0,0,85.66,77.66ZM200,168a12,12,0,1,0-12,12A12,12,0,0,0,200,168Z"></path>
                    </svg>
                    <input
                      onChange={() => {
                        document.getElementById('RegisterformFileName').innerText = fileInputRef.current.files[0].name;
                      }}
                      id="FormFileBlock-Document"
                      type="file"
                      ref={fileInputRef}
                    />{' '}
                  </label>
                  <p id="RegisterformFileName"></p>
                  <button
                    id="Registerform-de-send-data"
                    onClick={() => {
                      sendDocs();
                    }}
                  >
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[8]}
                  </button>
                  <p id="senderror-text"></p>
                </div>
              </div>
            </div>
          )}

          {registerstep == 3 && (
            <div className="Registerform-step2-wrap" id="Registerform-step3-wrap">
              <div className="Registerform-SuccesButton-wrap">
                <span className="Registerform-SuccesButton">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M21.7969 7.54695L9.79687 19.547C9.69236 19.6518 9.56816 19.735 9.43142 19.7918C9.29467 19.8486 9.14806 19.8778 9 19.8778C8.85193 19.8778 8.70532 19.8486 8.56858 19.7918C8.43183 19.735 8.30764 19.6518 8.20312 19.547L2.95312 14.297C2.84848 14.1923 2.76547 14.0681 2.70883 13.9313C2.6522 13.7946 2.62305 13.6481 2.62305 13.5001C2.62305 13.3521 2.6522 13.2055 2.70883 13.0688C2.76547 12.9321 2.84848 12.8078 2.95312 12.7032C3.05777 12.5986 3.182 12.5155 3.31873 12.4589C3.45546 12.4023 3.60201 12.3731 3.75 12.3731C3.89799 12.3731 4.04454 12.4023 4.18126 12.4589C4.31799 12.5155 4.44223 12.5986 4.54687 12.7032L9.00094 17.1573L20.205 5.95508C20.4163 5.74373 20.703 5.625 21.0019 5.625C21.3008 5.625 21.5874 5.74373 21.7987 5.95508C22.0101 6.16642 22.1288 6.45307 22.1288 6.75195C22.1288 7.05084 22.0101 7.33748 21.7987 7.54883L21.7969 7.54695Z"
                      fill="white"
                    />
                  </svg>
                </span>
              </div>

              <div className="Registerform-step2-text-wrap">
                <div className="Registerform-step2-text-wrap-inner">
                  <h2>{sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[6]}</h2>
                  <p>{sysdata.data.length > 0 && sysdata.data[0].attributes.FreeText2}</p>
                  <Link to={'/' + param.count + '/'}>
                    {sysdata.data.length > 0 && sysdata.data[0].attributes.PageTranslations.data[5]}
                  </Link>
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
      <NeedHelp color="grey"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default Registerform;
