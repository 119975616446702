import React, { useEffect, useState } from 'react';
import './GeneralCss.css';
import LinkData from './Links.json';
import { Link, useParams } from 'react-router-dom';

function ConfiBlock(props) {
  let para = useParams();
  let [linkarr, setlinkarr] = useState([]);
  let [wordarr, setwordarr] = useState([]);
  let [wordarr2, setwordarr2] = useState({ data: [] });
  let param = useParams();
  let arrla;
  let la;
  let arrla1 = '';
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }
  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };

  useEffect(() => {
    decidethepaths();

    const cachedData = localStorage.getItem(para.count + '-cachedDataConfiEntry');
    if (cachedData) {
      const now = new Date();
      // compare the expiry time of the item with the current time
      setwordarr2(JSON.parse(cachedData).json);
      setwordarr(JSON.parse(cachedData).json.data[0].attributes.PageTranslations.data);
      if (now.getTime() > JSON.parse(cachedData).expiry) {
        localStorage.removeItem(para.count + '-cachedDataConfiEntry');
      }
    } else {
      let now = new Date();
      fetch(
        'https://strapi-qrailing.azurewebsites.net/api/components?locale=' +
          arrla1 +
          '&filters[Slug][$eq]=ConfiguratorEntryBlock',
        requestOptions,
      )
        .then((res) => res.json())
        .then((json) => {
          setwordarr2(json);
          setwordarr(json.data[0].attributes.PageTranslations.data);
          localStorage.setItem(
            para.count + '-cachedDataConfiEntry',
            JSON.stringify({ json, expiry: now.getTime() + 5 * 60 * 1000 }),
          );
        });
    }
  }, []);

  useEffect(() => {
    if (props.color && wordarr.length > 0) {
      if (props.color == 'grey') {
        document.querySelector('.Configure-block-wrap').style = 'background-color: #f2f3f4 !important;';
        document.querySelectorAll('.Configure-block-card-btnrow')[0].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-btnrow')[1].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-btnrow')[2].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-btnrow')[3].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-btnrow')[4].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-text')[0].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-text')[1].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-text')[2].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-text')[3].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-text')[4].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-link')[0].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-link')[1].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-link')[2].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-link')[3].style = 'background-color: white !important;';
        document.querySelectorAll('.Configure-block-card-link')[4].style = 'background-color: white !important;';
      }
    }
    if (wordarr.length > 0) {
      if (wordarr[1].length > 24 || wordarr[2].length > 24 || wordarr[3].length > 24) {
        document.querySelectorAll('.Configure-block-card-btnrow')[0].style =
          'padding-bottom:122px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[1].style =
          'padding-bottom:122px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[2].style =
          'padding-bottom:122px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[3].style =
          'padding-bottom:122px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[4].style =
          'padding-bottom:122px !important;background-color:white;';
      }

      if (wordarr[1].length > 33 || wordarr[2].length > 33 || wordarr[3].length > 33) {
        document.querySelectorAll('.Configure-block-card-btnrow')[0].style =
          'padding-bottom:145px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[1].style =
          'padding-bottom:145px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[2].style =
          'padding-bottom:145px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[3].style =
          'padding-bottom:145px !important;background-color:white;';
        document.querySelectorAll('.Configure-block-card-btnrow')[4].style =
          'padding-bottom:145px !important;background-color:white;';
      }
    }
  }, [wordarr]);

  useEffect(() => {
    if (props.slug == 'glassrailing') {
      if (document.getElementById('Configure-block-card3')) {
        document.getElementById('Configure-block-card3').style = 'display:none !important';
      }
    }
    if (props.slug == 'stairs') {
      if (document.getElementById('Configure-block-card1')) {
        document.getElementById('Configure-block-card1').style = 'display:none !important';
      }
      if (document.getElementById('Configure-block-card3')) {
        document.getElementById('Configure-block-card3').style = 'display:none !important';
      }
      if (document.getElementById('Configure-block-card4')) {
        document.getElementById('Configure-block-card4').style = 'display:none !important';
      }
      if (document.getElementById('Configure-block-card5')) {
        document.getElementById('Configure-block-card5').style = 'display:none !important';
      }
    }
  }, [wordarr]);

  function decidethepaths() {
    if (LinkData) {
      let val = ['en', 'en'];
      if (para.count) {
        if (para.count.match('-')) {
          val = para.count.split('-');
        }
      }
      if (para.count == 'es') {
        val = ['es', 'es'];
      }

      switch (val[0]) {
        case 'de':
          setlinkarr(LinkData.data.de_arr);
          break;
        case 'da':
          setlinkarr(LinkData.data.da_arr);
          break;
        case 'en':
          setlinkarr(LinkData.data.globallangarr);
          break;
        case 'it':
          setlinkarr(LinkData.data.it_arr);
          break;
        case 'es':
          setlinkarr(LinkData.data.es_arr);
          break;
        case 'fi':
          setlinkarr(LinkData.data.fi_arr);
          break;
        case 'fr':
          setlinkarr(LinkData.data.fr_arr);
          break;
        case 'nb':
          setlinkarr(LinkData.data.nb_arr);
          break;
        case 'nl':
          setlinkarr(LinkData.data.nl_arr);
          break;
        case 'pl':
          setlinkarr(LinkData.data.pl_arr);
          break;
        case 'pt':
          setlinkarr(LinkData.data.pt_arr);
          break;
        case 'sv':
          setlinkarr(LinkData.data.sv_arr);
          break;
        case 'tr':
          setlinkarr(LinkData.data.tr_arr);
          break;

        default:
          setlinkarr(LinkData.data.en_arr);
          break;
      }
      if (val[0] == 'en' && val[1] == 'us') {
        setlinkarr(LinkData.data.en_us_arr);
      }
      if (val[0] == 'en' && val[1] == 'ca') {
        setlinkarr(LinkData.data.en_us_arr);
      }
    }
  }

  return (
    <>
      {wordarr.length > 0 && (
        <div className="Configure-block-wrap" height="710px" width="100%">
          <div className="Configure-block-inner">
            <div className="Configure-block-head">
              <h2>{wordarr[0]} </h2>
            </div>
            <div className="Configure-block-cards">
              <div className="Configure-block-card" id="Configure-block-card1">
                <Link
                  className="Configure-block-card-link"
                  hrefLang={para.count}
                  to={'/' + para.count + linkarr[1] + '?ea'}
                >
                  <img
                    src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/easyaluglassentry.png'}
                    width={'256px'}
                    loading="lazy"
                    alt="easy-Alu card"
                  />
                  <p className="Configure-block-card-text">
                    {wordarr2.data.length > 0 && wordarr2.data[0].attributes.FreeTitle1}
                  </p>
                  <div className="Configure-block-card-btnrow">
                    <h3>{wordarr[1]}</h3>
                    <a hrefLang={para.count} href={'/' + para.count + linkarr[1] + '?ea'}>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3636 6.53061L7.86363 11.0306C7.72274 11.1715 7.53164 11.2507 7.33238 11.2507C7.13313 11.2507 6.94203 11.1715 6.80113 11.0306C6.66024 10.8897 6.58108 10.6986 6.58108 10.4994C6.58108 10.3001 6.66024 10.109 6.80113 9.96811L10.0205 6.74999H0.833008C0.634095 6.74999 0.44333 6.67097 0.302678 6.53032C0.162025 6.38967 0.0830078 6.1989 0.0830078 5.99999C0.0830078 5.80108 0.162025 5.61031 0.302678 5.46966C0.44333 5.329 0.634095 5.24999 0.833008 5.24999H10.0205L6.80238 2.02999C6.66149 1.88909 6.58233 1.69799 6.58233 1.49874C6.58233 1.29948 6.66149 1.10838 6.80238 0.967487C6.94328 0.826591 7.13438 0.747437 7.33363 0.747437C7.53289 0.747437 7.72399 0.826591 7.86488 0.967487L12.3649 5.46749C12.4348 5.53726 12.4903 5.62016 12.5281 5.71142C12.5659 5.80269 12.5853 5.90052 12.5852 5.99931C12.585 6.09809 12.5654 6.19588 12.5274 6.28706C12.4894 6.37824 12.4337 6.46101 12.3636 6.53061Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </Link>
              </div>
              <div className="Configure-block-card" id="Configure-block-card2">
                <Link
                  className="Configure-block-card-link"
                  hrefLang={para.count}
                  to={'/' + para.count + linkarr[1] + '?egs'}
                >
                  <img
                    src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/easyglasssmartentry.png'}
                    width={'256px'}
                    loading="lazy"
                    alt="easy-glass-smart card"
                  />
                  <p className="Configure-block-card-text">
                    {wordarr2.data.length > 0 && wordarr2.data[0].attributes.FreeTitle2}
                  </p>
                  <div className="Configure-block-card-btnrow">
                    <h3>{wordarr[2]}</h3>
                    <a hrefLang={para.count} href={'/' + para.count + linkarr[1] + '?egs'}>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3636 6.53061L7.86363 11.0306C7.72274 11.1715 7.53164 11.2507 7.33238 11.2507C7.13313 11.2507 6.94203 11.1715 6.80113 11.0306C6.66024 10.8897 6.58108 10.6986 6.58108 10.4994C6.58108 10.3001 6.66024 10.109 6.80113 9.96811L10.0205 6.74999H0.833008C0.634095 6.74999 0.44333 6.67097 0.302678 6.53032C0.162025 6.38967 0.0830078 6.1989 0.0830078 5.99999C0.0830078 5.80108 0.162025 5.61031 0.302678 5.46966C0.44333 5.329 0.634095 5.24999 0.833008 5.24999H10.0205L6.80238 2.02999C6.66149 1.88909 6.58233 1.69799 6.58233 1.49874C6.58233 1.29948 6.66149 1.10838 6.80238 0.967487C6.94328 0.826591 7.13438 0.747437 7.33363 0.747437C7.53289 0.747437 7.72399 0.826591 7.86488 0.967487L12.3649 5.46749C12.4348 5.53726 12.4903 5.62016 12.5281 5.71142C12.5659 5.80269 12.5853 5.90052 12.5852 5.99931C12.585 6.09809 12.5654 6.19588 12.5274 6.28706C12.4894 6.37824 12.4337 6.46101 12.3636 6.53061Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </Link>
              </div>
              <div className="Configure-block-card" id="Configure-block-card3">
                <Link
                  className="Configure-block-card-link"
                  hrefLang={para.count}
                  to={'/' + para.count + linkarr[1] + '?ep'}
                >
                  <img
                    src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/easyalupicketentry.png'}
                    width={'256px'}
                    loading="lazy"
                    alt="easy-Alu-picket card"
                  />
                  <p className="Configure-block-card-text">
                    {wordarr2.data.length > 0 && wordarr2.data[0].attributes.FreeTitle3}
                  </p>
                  <div className="Configure-block-card-btnrow">
                    <h3>{wordarr[3]}</h3>
                    <a hrefLang={para.count} href={'/' + para.count + linkarr[1] + '?ep'}>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3636 6.53061L7.86363 11.0306C7.72274 11.1715 7.53164 11.2507 7.33238 11.2507C7.13313 11.2507 6.94203 11.1715 6.80113 11.0306C6.66024 10.8897 6.58108 10.6986 6.58108 10.4994C6.58108 10.3001 6.66024 10.109 6.80113 9.96811L10.0205 6.74999H0.833008C0.634095 6.74999 0.44333 6.67097 0.302678 6.53032C0.162025 6.38967 0.0830078 6.1989 0.0830078 5.99999C0.0830078 5.80108 0.162025 5.61031 0.302678 5.46966C0.44333 5.329 0.634095 5.24999 0.833008 5.24999H10.0205L6.80238 2.02999C6.66149 1.88909 6.58233 1.69799 6.58233 1.49874C6.58233 1.29948 6.66149 1.10838 6.80238 0.967487C6.94328 0.826591 7.13438 0.747437 7.33363 0.747437C7.53289 0.747437 7.72399 0.826591 7.86488 0.967487L12.3649 5.46749C12.4348 5.53726 12.4903 5.62016 12.5281 5.71142C12.5659 5.80269 12.5853 5.90052 12.5852 5.99931C12.585 6.09809 12.5654 6.19588 12.5274 6.28706C12.4894 6.37824 12.4337 6.46101 12.3636 6.53061Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </Link>
              </div>
              <div className="Configure-block-card" id="Configure-block-card4">
                <Link
                  className="Configure-block-card-link"
                  hrefLang={para.count}
                  to={'/' + para.count + linkarr[1] + '?bc'}
                >
                  <img
                    src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/baseclampsentry.png'}
                    width={'256px'}
                    loading="lazy"
                    alt="easy-Alu-picket card"
                  />
                  <p className="Configure-block-card-text">
                    {wordarr2.data.length > 0 && wordarr2.data[0].attributes.FreeText1}
                  </p>
                  <div className="Configure-block-card-btnrow">
                    <h3>{wordarr[5]}</h3>
                    <a hrefLang={para.count} href={'/' + para.count + linkarr[1] + '?ep'}>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3636 6.53061L7.86363 11.0306C7.72274 11.1715 7.53164 11.2507 7.33238 11.2507C7.13313 11.2507 6.94203 11.1715 6.80113 11.0306C6.66024 10.8897 6.58108 10.6986 6.58108 10.4994C6.58108 10.3001 6.66024 10.109 6.80113 9.96811L10.0205 6.74999H0.833008C0.634095 6.74999 0.44333 6.67097 0.302678 6.53032C0.162025 6.38967 0.0830078 6.1989 0.0830078 5.99999C0.0830078 5.80108 0.162025 5.61031 0.302678 5.46966C0.44333 5.329 0.634095 5.24999 0.833008 5.24999H10.0205L6.80238 2.02999C6.66149 1.88909 6.58233 1.69799 6.58233 1.49874C6.58233 1.29948 6.66149 1.10838 6.80238 0.967487C6.94328 0.826591 7.13438 0.747437 7.33363 0.747437C7.53289 0.747437 7.72399 0.826591 7.86488 0.967487L12.3649 5.46749C12.4348 5.53726 12.4903 5.62016 12.5281 5.71142C12.5659 5.80269 12.5853 5.90052 12.5852 5.99931C12.585 6.09809 12.5654 6.19588 12.5274 6.28706C12.4894 6.37824 12.4337 6.46101 12.3636 6.53061Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </Link>
              </div>
              <div className="Configure-block-card" id="Configure-block-card5">
                <Link
                  className="Configure-block-card-link"
                  hrefLang={para.count}
                  to={'/' + para.count + linkarr[1] + '?fb'}
                >
                  <img
                    src={'https://strapiwmc4hstore.blob.core.windows.net/website-assets/easyglassview_configentry.webp'}
                    width={'256px'}
                    loading="lazy"
                    alt="easy-glass-view card"
                  />
                  <p className="Configure-block-card-text">{wordarr[7]}</p>
                  <div className="Configure-block-card-btnrow">
                    <h3>{wordarr[6]}</h3>
                    <a hrefLang={para.count} href={'/' + para.count + linkarr[1] + '?fb'}>
                      <svg width="13" height="12" viewBox="0 0 13 12" fill="" xmlns="http://www.w3.org/2000/svg">
                        <path
                          d="M12.3636 6.53061L7.86363 11.0306C7.72274 11.1715 7.53164 11.2507 7.33238 11.2507C7.13313 11.2507 6.94203 11.1715 6.80113 11.0306C6.66024 10.8897 6.58108 10.6986 6.58108 10.4994C6.58108 10.3001 6.66024 10.109 6.80113 9.96811L10.0205 6.74999H0.833008C0.634095 6.74999 0.44333 6.67097 0.302678 6.53032C0.162025 6.38967 0.0830078 6.1989 0.0830078 5.99999C0.0830078 5.80108 0.162025 5.61031 0.302678 5.46966C0.44333 5.329 0.634095 5.24999 0.833008 5.24999H10.0205L6.80238 2.02999C6.66149 1.88909 6.58233 1.69799 6.58233 1.49874C6.58233 1.29948 6.66149 1.10838 6.80238 0.967487C6.94328 0.826591 7.13438 0.747437 7.33363 0.747437C7.53289 0.747437 7.72399 0.826591 7.86488 0.967487L12.3649 5.46749C12.4348 5.53726 12.4903 5.62016 12.5281 5.71142C12.5659 5.80269 12.5853 5.90052 12.5852 5.99931C12.585 6.09809 12.5654 6.19588 12.5274 6.28706C12.4894 6.37824 12.4337 6.46101 12.3636 6.53061Z"
                          fill="white"
                        />
                      </svg>
                    </a>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}

      {wordarr.length == 0 && <div id="ConfiBlock-shadowdiv"></div>}
    </>
  );
}

export default ConfiBlock;
