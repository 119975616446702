import React, { useEffect, useState } from 'react';

import HeaderSlim from './includes/HeaderSlim';
import './Jobcenter.css';
import { useParams, useLocation } from 'react-router-dom';
import Footernew from './includes/Footernew';
import FormFileBlock from './includes/FormFileBlock';
import './Trainings.css';
import { Helmet } from 'react-helmet';

function TrainingsDetail() {
  const params = useParams();
  let [systemres, setsystemres] = useState({ data: [] });
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  let la;
  let arrla;
  let arrla1 = '';
  let param = useParams();
  if (param.count) {
    la = param.count;
    arrla = la.split('-');
    if (param.count == 'en') {
      arrla = ['en', 'INTERNATIONAL'];
    }
    if (param.count == 'es') {
      arrla = ['es', 'INTERNATIONAL'];
    }
    if (arrla[1]) {
      arrla[1] = arrla[1].toUpperCase();
      arrla1 = arrla[0] + '-' + arrla[1];
      if (arrla[1] == 'INTERNATIONAL') {
        arrla1 = arrla[0];
      }
    }
  } else {
    arrla1 = 'en';
  }

  let token = process.env.REACT_APP_PUBLIC_KEY2;
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${token}` },
  };
  useEffect(() => {
    fetch(
      'https://strapi-qrailing.azurewebsites.net/api/trainings?locale=' +
        arrla1 +
        '&populate=BannerImage&filters[Slug][$eq]=' +
        params.training,
      requestOptions,
    )
      .then((res) => res.json())
      .then((json) => {
        setsystemres(json);
        console.log(json);
      });
  }, []);

  function renderjob() {
    if (systemres.data.length > 0) {
      return (
        <>
          <div className="jobcenterdetail">
            <div>
              <h2>{systemres.data[0].attributes.Title}</h2>
              <p>
                {systemres.data[0].attributes.Date} - {systemres.data[0].attributes.Location}
              </p>
            </div>

            <button
              onClick={() =>
                document.querySelector('.FormFileBlock-warp').scrollIntoView({ behavior: 'smooth', block: 'center' })
              }
            >
              Apply now
            </button>
          </div>
          <div className="jobcenterdetail-banner">
            <img
              alt="banner-training"
              src={
                systemres.data[0].attributes.BannerImage
                  ? systemres.data[0].attributes.BannerImage.data[0].attributes.url
                  : 'https://q-railing.s3.eu-central-1.amazonaws.com/images/job4.jpg'
              }
            />
          </div>
          <div className="TrainingsDetail-text">
            <div>
              <h3>{systemres.data[0].attributes.PageTranslations.data[0]} </h3>
            </div>

            <div
              key={'CustomTextTrainings'}
              dangerouslySetInnerHTML={{ __html: systemres.data[0].attributes.TrainingCustomText }}
            />
          </div>
        </>
      );
    }
  }

  return (
    <>
      <Helmet>
        <title>{systemres.data.length > 0 ? systemres.data[0].attributes.MetaTitle : 'Training'}</title>
        <meta
          name="description"
          content={systemres.data.length > 0 ? systemres.data[0].attributes.MetaText : 'Q-railing Training'}
        ></meta>

        <link
          rel="alternate"
          hreflang="da-DK"
          href={'https://q-railing.com/da-dk/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-AT"
          href={'https://q-railing.com/de-at/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-CH"
          href={'https://q-railing.com/de-ch/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-DE"
          href={'https://q-railing.com/de-de/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={'https://q-railing.com/en/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-AT"
          href={'https://q-railing.com/en-at/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-CA"
          href={'https://q-railing.com/en-ca/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-GB"
          href={'https://q-railing.com/en-gb/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-IE"
          href={'https://q-railing.com/en-ie/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-US"
          href={'https://q-railing.com/en-us/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="es"
          href={'https://q-railing.com/es/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="es-ES"
          href={'https://q-railing.com/es-es/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="es-US"
          href={'https://q-railing.com/es-us/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fi-FI"
          href={'https://q-railing.com/fi-fi/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-BE"
          href={'https://q-railing.com/fr-be/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-CA"
          href={'https://q-railing.com/fr-ca/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-CH"
          href={'https://q-railing.com/fr-ch/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-FR"
          href={'https://q-railing.com/fr-fr/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-LU"
          href={'https://q-railing.com/fr-lu/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="it-CH"
          href={'https://q-railing.com/it-ch/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="it-IT"
          href={'https://q-railing.com/it-it/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="nb-NO"
          href={'https://q-railing.com/nb-no/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="nl-BE"
          href={'https://q-railing.com/nl-be/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="nl-NL"
          href={'https://q-railing.com/nl-nl/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="pl-PL"
          href={'https://q-railing.com/pl-pl/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="pt-BR"
          href={'https://q-railing.com/pt-br/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="pt-PT"
          href={'https://q-railing.com/pt-pt/service/trainings/' + params.training + '/'}
        />
        <link
          rel="alternate"
          hreflang="sv-SE"
          href={'https://q-railing.com/sv-se/service/trainings/' + params.training + '/'}
        />
      </Helmet>
      <HeaderSlim></HeaderSlim>
      <div className="jobcenterdetail-wrap">
        <div className="jobcenterdetail-inner">{renderjob()}</div>
      </div>

      {systemres.data.length > 0 && (
        <FormFileBlock
          trainingsdate={systemres.data[0].attributes.Date}
          trainingsmail={systemres.data[0].attributes.MailContact}
          trainingsverteiler={systemres.data[0].attributes.MailVerteiler}
          slugdata={params.training}
          file={'nofile'}
          type={'training'}
        ></FormFileBlock>
      )}
      <Footernew></Footernew>
    </>
  );
}

export default TrainingsDetail;
