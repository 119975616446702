import React from 'react';
import HeaderSlim from './includes/HeaderSlim';
import Servicesearch from './includes/Servicesearch';
import { useParams } from 'react-router-dom';
import Footernew from './includes/Footernew';
import NeedHelp from './includes/NeedHelp';
import { Helmet } from 'react-helmet';

function Downloads() {
  let param = useParams();

  return (
    <>
      <Helmet>
        <title>Downloads</title>
        <meta name="description" content="Q-railing downloads"></meta>

        <link
          rel="alternate"
          hreflang="da-DK"
          href={'https://q-railing.com/da-dk/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-AT"
          href={'https://q-railing.com/de-at/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-CH"
          href={'https://q-railing.com/de-ch/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="de-DE"
          href={'https://q-railing.com/de-de/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="x-default"
          href={'https://q-railing.com/en/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-AT"
          href={'https://q-railing.com/en-at/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-CA"
          href={'https://q-railing.com/en-ca/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-GB"
          href={'https://q-railing.com/en-gb/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-IE"
          href={'https://q-railing.com/en-ie/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="en-US"
          href={'https://q-railing.com/en-us/service/downloads/' + param.dllcat + '/'}
        />
        <link rel="alternate" hreflang="es" href={'https://q-railing.com/es/service/downloads/' + param.dllcat + '/'} />
        <link
          rel="alternate"
          hreflang="es-ES"
          href={'https://q-railing.com/es-es/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="es-US"
          href={'https://q-railing.com/es-us/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fi-FI"
          href={'https://q-railing.com/fi-fi/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-BE"
          href={'https://q-railing.com/fr-be/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-CA"
          href={'https://q-railing.com/fr-ca/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-CH"
          href={'https://q-railing.com/fr-ch/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-FR"
          href={'https://q-railing.com/fr-fr/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="fr-LU"
          href={'https://q-railing.com/fr-lu/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="it-CH"
          href={'https://q-railing.com/it-ch/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="it-IT"
          href={'https://q-railing.com/it-it/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="nb-NO"
          href={'https://q-railing.com/nb-no/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="nl-BE"
          href={'https://q-railing.com/nl-be/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="nl-NL"
          href={'https://q-railing.com/nl-nl/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="pl-PL"
          href={'https://q-railing.com/pl-pl/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="pt-BR"
          href={'https://q-railing.com/pt-br/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="pt-PT"
          href={'https://q-railing.com/pt-pt/service/downloads/' + param.dllcat + '/'}
        />
        <link
          rel="alternate"
          hreflang="sv-SE"
          href={'https://q-railing.com/sv-se/service/downloads/' + param.dllcat + '/'}
        />
      </Helmet>
      <HeaderSlim key={param.dllcat}></HeaderSlim>
      <Servicesearch filter={'nofilter'}></Servicesearch>
      <NeedHelp color="white"></NeedHelp>
      <Footernew></Footernew>
    </>
  );
}

export default Downloads;
